// https://mui.com/material-ui/react-card/

import React, { useState, useRef, useCallback, FC, useEffect} from 'react';

import {
  useQuery,
  useMutation,
  gql,
} from "@apollo/client";

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red, pink, green, blue, grey } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Slider from '@mui/material/Slider';
import RotateLeft from '@mui/icons-material/RotateLeft';
import RotateRight from '@mui/icons-material/RotateRight';
import CancelIcon from '@mui/icons-material/Cancel';
import ImageIcon from '@mui/icons-material/Image';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { MuiColorInput,  MuiColorInputValue, MuiColorInputColors, MuiColorInputFormat } from 'mui-color-input'

import { makeStyles } from '@mui/styles';

import axios from 'axios'
import ReactAvatarEditor, { Position }  from 'react-avatar-editor'
import InputRange, { Range } from 'react-input-range';

import { useDropzone }  from 'react-dropzone'
import EXIF from 'exif-js';
import { array } from 'yup';
import { MenuItem } from '@mui/material';
import { DeviceUnknown } from '@mui/icons-material';

const avatarModalStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
  dropZone: {
    width: 250,
    height: 250,
    borderWidth: 2,
    borderColor: '#757e84',
    borderStyle: 'dashed',
    borderRadius: '15%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  avatarEditor: {
    display: 'flex',
    flexDirection: 'column'
  },
  avatarEditorBg: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    backgroundImage: `linear-gradient(45deg, #a3a9ad 25%, transparent 25%),
    linear-gradient(-45deg, #a3a9ad 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #a3a9ad 75%),
    linear-gradient(-45deg, transparent 75%, #a3a9ad 75%)`,
    backgroundSize: '20px 20px',
    backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px'
  },
  avatarEditorClose: {
    position: 'absolute',
    right: '5px',
    color: 'white'
  },
  avatarSliderShell: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    marginTop: '5px'
  }
}));


//---------------------------------------------------------------
// http://localhost:3000/card/62beb9beb4a19a4c82d096ae
const getCardGQL = gql`
query getCard($id: ID!){
    getCard( id:$id){
    id
    card{
        id
        title
        category
        data
        img
      }
    success
    error
    }
}
`;


export const updateCardMGQL = gql`
mutation updateCardM( $id: ID! $input:CardUpdate!){
  updateCardM( id:$id, input: $input) {
    success
    error
    id
  }
}
`;

function BgUpdate( {cardid, bgimage}) {

  const [uploadStatus, setStatus] = useState(false);
  const [updateCardM, { loading, error, data }] = useMutation(updateCardMGQL, {
    refetchQueries: [getCardGQL, "getCard"]
  });


  //console.log('BgUpdate---');
  useEffect(()=>{
    if( data !== undefined ){
      //let jdata = JSON.parse(data.updateCardM);
      //alert(jdata);
    }
  },[data]);

  if (loading) return <p>image update ...</p>;
  if (error) return <p>Error!: {error.message}</p>;
  if (data !== undefined ){
    console.log(data);
    //if( data.updateCardM.success === true ) setStatus(true);
  }

  function updateCard(){
    console.log(cardid+"/"+bgimage);
    updateCardM({ variables: {   
      "id": cardid, 
      "input": {
        //"title": "",
        //"category": "switch",
        "img": "fileServer/images/"+bgimage
      }
    }
    });
  }

  return(
    <Avatar sx={{ bgcolor: grey[500] }} onClick={updateCard} >
    { (data === undefined || data.updateCardM.success !== true) && <ArrowUpwardIcon />}
    { (data !== undefined && data.updateCardM.success === true) && <DoneIcon />}
    </Avatar>
  );
}


export const BgCard: FC<Props> = ({cardid, title}) => {

  const [image, setImage] = useState("");
  const [upload, setUpload] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("");
  const [selImg, setSelImg] = useState<{
    index: number;
    id: string;
  } | null>({index:0, id:""});
  //const [width, setWidth] = useState(0);
  //const [height, setHeight] = useState(0);
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] =  useState(1);  
  const [position, setPosition] = useState<Position>();
  const [avatar, setAvatar] = useState<{
    file?: unknown;
    preview: string;
  } | null>(null);

  const [color, setColor] = useState([0,0,0,0]);
  const [scolor, setSColor] = useState('#000000FF');
  const handleColorChange = (newValue: string, colors: MuiColorInputColors) => {
    console.log("change", colors.hex8);
    setSColor(colors.hex8);
    // setColor(`#${newValue.hex}`);
    var r = parseInt(colors.hex8.slice(1, 3), 16),
        g = parseInt(colors.hex8.slice(3, 5), 16),
        b = parseInt(colors.hex8.slice(5, 7), 16),
        a = parseInt(colors.hex8.slice(7, 9), 16);
    setColor([r,g,b,a]);
    // action('changed')(newValue);
  };
  const format: MuiColorInputFormat = 'hex'


  const { loading, error, data, refetch, networkStatus } = useQuery(
    getCardGQL,
    {
      variables: { "id": cardid},
      //notifyOnNetworkStatusChange: true
      // pollInterval: 500
      onCompleted: (data) => {
        //`${card.img.replace(/fileServer/i, "https://liflo.io")}`
        let imgName = `${data.getCard.card.img.replace(/fileServer\/images\//i, "")}`
        setImage(imgName);

        const image = {
          preview: "https://liflo.io/images/"+(imgName === "" ? "file-1658751593961.png":imgName),
          crossOrigin: "Anonymous"
        };
        setAvatar(image);
      },
    }
  );  

  useEffect(() => {
  }, []);

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    //alert('onDrop');
    if (acceptedFiles.length === 0) return;
    const file = acceptedFiles[0];
    const image = {
      file,
      preview: URL.createObjectURL(file),
      crossOrigin: "anonymous"
    };
    setAvatar(image);
  }, []);

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop });

  const classes: Record<
    | 'root'
    | 'dropZone'
    | 'avatarEditor'
    | 'avatarEditorBg'
    | 'avatarEditorClose'
    | 'avatarSliderShell',
    string
  > = avatarModalStyles();

  let styles = {};
  if (isDragActive) {
    styles = {
      borderStyle: 'solid',
      borderColor: '#6c6',
      backgroundColor: '#eee'
    };
  } else if (isDragReject) {
    styles = {
      borderStyle: 'solid',
      borderColor: '#c66',
      backgroundColor: '#eee'
    };
  }

  const ratio = 1.618;
  const width = 250;
  const height = width*ratio;
  
  const  editorRef = useRef<ReactAvatarEditor>(null);

  const closeWindow = e => {
    e.preventDefault()
    //  alert('size(wxh):'+this.state.width+'x'+this.state.height);
    /*
    try {
      window.opener.onClose("hello this!");
    }
    catch (err) {
      alert(err);
    }
    */
    window.close();
    return false;
  }


const uploadPhoto = async () => {
  if (editorRef.current == null) return;
  setUpload(false);

  
  const canvas = editorRef.current.getImage(); // This is a HTMLCanvasElement.
  // It can be made into a data URL or a blob, drawn on another canvas, or added to the DOM.
  
  // If you want the image resized to the canvas size (also a HTMLCanvasElement)
  //const canvas = editorRef.current.getImageScaledToCanvas();    
  //var canvasScaled = editorRef.current.getImageScaledToCanvas();
  //canvas.crossOrigin = 'anonymous';
  fileUpload(canvas);
};

const fileUpload = (canvas) => {
  const data = new FormData()
  canvas.toBlob((blob) => {
    data.append('file', blob, 'check.png')

    axios
      .post(`https://liflo.io/upload`, data, {
        // .post(`https://localhost:3006/upload`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        console.log(`fileUpload:${res.data.filename}`)
        
        setImage(res.data.filename)
        setUpload(true);
        setUploadStatus("completed:"+res.data.filename);

      })
  })
}

const handleScale = e => {
  
    const scale = e.target.value;
    console.log('scale:'+scale);
    setScale(scale);
  
}


  const rotateLeft = e => {
    
    e.preventDefault();
    //  alert('size(wxh):'+this.state.width+'x'+this.state.height);
    setRotate(rotate - 90);
  }

  const rotateRight = e => {
    e.preventDefault()
    //  alert('size(wxh):'+this.state.width+'x'+this.state.height);
    setRotate(rotate + 90);
  }

  
  return (
    <Card sx={{ maxWidth: "300", Height: "550", borderRadius: 3 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            Bg2
          </Avatar>
        }
        action={
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={{ xs: 1, sm: 1, md: 1 }}>
          <BgUpdate cardid={cardid} bgimage={image}/>
          <Avatar sx={{ bgcolor: grey[500] }} onClick={closeWindow} >
                  <ClearIcon />
          </Avatar>
          </Stack>
        }
        title={"background image"}
        subheader={uploadStatus}
      >
      </CardHeader>
 
      <CardContent sx={{ pt:0}}>

        { avatar === null ? (
          <div>
          </div>
        ) : (
          <div className={classes.avatarEditor}>
            <div className={classes.avatarEditorBg}>
              <ReactAvatarEditor
                width={width}
                height={height}
                ref={editorRef}
                image={avatar.preview}
                color={color}
                backgroundColor={scolor}
                position={position}
                scale={scale}
                rotate={rotate}
                onPositionChange={position => setPosition(position)}
              />
              <span className={classes.avatarEditorClose}>
                {loading ? (
                  <div>Loading...</div>
                ) : (
                  <Avatar sx={{ bgcolor: grey[500] }} onClick={uploadPhoto} >
                  <DoneIcon />
                  </Avatar>                )}
              </span>
            </div>
          </div>)
        }
            <div className={classes.avatarSliderShell}>
            <Stack direction="row" justifyContent="center" spacing={{ xs: 2, sm: 1, md: 1 }}>
              <div
              style={styles} 
              {...getRootProps()}>
                <input {...getInputProps()} />
                    <Avatar sx={{ bgcolor: blue[500] }}>
                        <PhotoCamera />
                    </Avatar>
              {isDragReject && <div>Unsupported file type...</div>}
              </div>
              <MuiColorInput  className="MuiColorInput-Popover" size={'small'} value={scolor} onChange={handleColorChange} format={'hex8'} variant="outlined" />
              <Avatar sx={{ bgcolor: blue[500] }} onClick={rotateLeft} >
                  <RotateLeft />
              </Avatar>
              <Avatar sx={{ bgcolor: blue[500] }} onClick={rotateRight} >
                  <RotateRight />
              </Avatar>
            </Stack>
            <span style={{ marginLeft: '10px' }}>
              <ImageIcon fontSize="small" />
            </span>     
            <Slider name="scale" aria-labelledby="label" onChange={handleScale} color="secondary"
          min={0.1} max={5} step={0.01} defaultValue={1}/>
          </div>
      </CardContent>
    </Card>
  );
}

interface Props {
  title?: string;
  cardid?: string;
}

//avatar === "null"