import React from "react";
import { initialize } from "./init";
import { StartCardNode } from "./Nodes/StartCard";
import { SwitchCardNode } from "./Nodes/SwitchCard";
import { EndCardNode } from "./Nodes/EndCard";
import { LinkCardNode } from "./Nodes/LinkCard";
import { InfoCardNode } from "./Nodes/InfoCard";
//import { ExitCardNode } from "./Nodes/ExitCard";
import { ActionCardNode } from "./Nodes/ActionCard";
//import { PoseCardNode } from "./Nodes/PoseCard";

import './editor.css';


export const init = async (htmlContainer: any, jsondata: string) => {
  const { editor, engine, resize, process } = await initialize(htmlContainer);

  const components = [
    new StartCardNode(), 
    new SwitchCardNode(),
    new ActionCardNode(),
    new LinkCardNode(),
//    new PoseCardNode(),
    new EndCardNode(),
    new InfoCardNode(),
    //new ExitCardNode()
  ];
  
  components.map((c) => {
    editor.register(c);
    engine.register(c);
  });

  let jdata = JSON.parse(jsondata);
  await editor.fromJSON(jdata); //data1);
  resize();
  process();

  return { editor, engine };
};
