import React from "react";


// MARK: Component
// eslint-disable-next-line @typescript-eslint/no-namespace
declare module Status {
    export type Props = {
        value: string;
        width: number;
        edit: boolean;
        key:string;
        onClick?: (key:any,action:any,data:any) => void;
    };
}

export class StatusControl extends React.Component<
    Status.Props
> {
    mounted!: boolean;

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(props: Status.Props) {
        super(props);
    }

    componentDidMount() {
        this.mounted = true;
    }


    render() {
        return (<></>
        );
    }
}

/**
import * as ReactDOM from 'react-dom';
import styled from "styled-components";
import { BotIndicator } from "./BotIndicator";
import { Icon } from "./Icon";


const Styled = {
    StatusContainer: styled.div`
        height: 72px;
    `
};
           <Styled.StatusContainer>
                <BotIndicator awaitingInput={this.props.value === "true"} />
            </Styled.StatusContainer>
 */
