import { NodeEditor, Engine } from "rete";
import ConnectionPlugin from "rete-connection-plugin";
//import { NodeBase } from "./Nodes/NodeBase";
import { ReactNode } from "./Nodes/ReactNode";

import { createRoot } from 'react-dom/client';
import ReactRenderPlugin from "rete-react-render-plugin";

import ContextMenuPlugin, {
  Menu,
  Item,
  Search
} from "rete-context-menu-plugin";

import AreaPlugin from "rete-area-plugin";

export var editor;

export async function initialize(
  container:any,
  connOptions = {},
  renderOptions = {}
) {
  editor = new NodeEditor("liflo@0.1.0", container);
  
  editor.use(ConnectionPlugin);
  editor.use(AreaPlugin);
  editor.use(ReactRenderPlugin, {component: ReactNode,createRoot});


  editor.use(ContextMenuPlugin, {
    searchBar: false,
    searchKeep: title => true,
    delay: 100,
    allocate(component) {
        return ["Create"];
    },
    rename(component) {
        return component.name;
    },
    items: {
        Save() {
            const filename = editor.id + ".json";
            downloadModel(filename);
        },
        MakeFlow() {
          const filename = editor.id + ".json";
          openFlow(filename);
        }
    },
    nodeItems: node => {
        if (node.name === "SwitchCard" || node.name === "PoseCard" || node.name === "ActionCard") {
            return {
                "->Start Card"() {
                    console.log("TODO: Make this the start card node");
                },
                Delete: true,
                Clone: true
            };
        } 
        else if (node.name === "StartCard" || node.name === "ExitCard" || node.name === "EndCard" || node.name === "LinkCard") {
            return {
                Delete: true,
                Clone: true
            };
        }
    }
  });

  const engine = new Engine("liflo@0.1.0");

  editor.on(
    "nodecreated noderemoved connectioncreated connectionremoved",  // process 
    async () => {
      if (editor.silent) return;

      await engine.abort();
      await engine.process(editor.toJSON());
    }
  );
  //console.log('check process busy');  
  return {
    editor,
    engine,
    resize() {
      editor.view.resize();
      AreaPlugin.zoomAt(editor);
    },
    process() {
      editor.trigger("process");
    },
  };
}

function downloadModel(filename) {
  const json = editor.toJSON();
  const jsonString = JSON.stringify(json, null, "\t");
  const element = document.createElement("a");
  element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(jsonString)
  );
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

function openFlow(filename) {
  const url = 'https://liflo.io';
  window.open(url, '_blank');
}