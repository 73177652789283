import React from "react";
import * as ReactDOM from 'react-dom';
import styled from "styled-components";
import { Icon } from "./Icon";
// MARK: Constants

const FontSize = 24;
const MinHeight = FontSize + 18;
const DefaultWidth = 65;
const DefaultHeight = MinHeight;

// MARK: Types



// MARK: Styles

const Styled = {
    Container: styled.div`
        position: relative;
        text-align: left;
        width: 100%;
        margin-bottom: 6px;
    `,
    TextArea: styled.textarea`
        background: transparent;
        border-style: none;
        box-shadow: none;
        display: block;
        font-family: "Roboto", sans-serif;
        font-size: ${FontSize}px;
        font-weight: lighter;
        margin: 8px 0;
        min-height: ${MinHeight}px;
        outline: none;
        overflow: hidden;
        padding: 0;
        text-align: left;
        transition: height 0.2s ease;
        width: 100%;
    `,
    TextAreaGhost: styled.textarea`
        opacity: 0.3;
        display: block;
        white-space: pre-wrap;
        word-wrap: break-word;
        visibility: hidden;
        position: absolute;
        top: 0;
        margin: 0;
        margin-left: 20px;
        padding: 4px;    

        background: transparent;
        border-style: none;
        box-shadow: none;
        font-family: "Roboto", sans-serif;
        font-size: ${FontSize}px;
        font-weight: lighter;
        margin: 8px 0;
        min-height: ${MinHeight}px;
        outline: none;
        overflow: hidden;
        padding: 0;
        text-align: left;
        transition: height 0.2s ease;
        width: 100%;
    `,    
    LabelTitle: styled.input`
        background: #ffffff;
        border-radius: 26px;
        border-width: 2px;
        border-style: solid;
        border-color: rgb(0, 177, 169);
        color: rgba(0, 0, 0, 0.87);
        cursor: pointer;
        display: inline-block;
        font-size: 12px;
        font-weight: normal;
        font-family: "Roboto", sans-serif;
        flex-direction: row;
        letter-spacing: normal;
        line-height: 14px;
        margin: 0;
        margin-left: 20px;
        padding: 0;
        text-align: center;
        text-indent: 0px;
        text-rendering: auto;
        text-shadow: none;
        text-transform: none;
        word-spacing: normal;
    `,
    LabelTitleGhost: styled.div`
        background: #cccccc;
        border-radius: 26px;
        border-width: 2px;
        border-style: solid;
        border-color: rgb(169, 177, 0);
        color: rgba(0, 0, 0, 0.87);
        height: 26px;
        cursor: pointer;
        display: inline-block;
        font-size: 24px;
        font-weight: normal;
        font-family: "Roboto", sans-serif;
        flex-direction: row;
        letter-spacing: normal;
        line-height: 14px;
        margin: 0;
        margin-left: 20px;
        padding: 2px;
        padding-top: 9px;
        padding-right: 5px;
        text-align: center;
        text-indent: 5px;
        text-rendering: auto;
        text-shadow: none;
        text-transform: none;
        word-spacing: normal;
`
/*
                <Styled.PrimaryAction>
                <Icon id={'1'} /><Icon id={'2'}/><Icon id={'3'}/><Icon id={'4'}/>
                </Styled.PrimaryAction>
*/
};

// MARK: Component
// eslint-disable-next-line @typescript-eslint/no-namespace
declare module LabelTitle {
    export type Props = {
        value: any;
        width: number;
        edit: boolean;
        key:string;
        onClick?: (key:any,action:any,data:any) => void;
    };

    export type State = {
        value?: string;
        width: number;
        height: number;
    };
}

export class ActionControl extends React.Component<
    LabelTitle.Props,
    LabelTitle.State
> {
    mounted!: boolean;
    capsule: any;
    ghost: any;
    state = {
        width: DefaultWidth,
        height: MinHeight,
        value: null,
        action: null,
        link: null
    };

    constructor(props: LabelTitle.Props) {
        super(props);
        
        let flink = props.value.body[1].action[1];
        let msg = '내용을 입력 하세요.';
        if( props.value.body[1].action[0] === "../Form/BaseCNT" || props.value.body[1].action[0] === "../Form/CNTBase" ) {
            msg = flink;
            flink = "";
        }

        this.state = {
            width: DefaultWidth,
            height: MinHeight,
            value: props.value.body[0] === ''?msg:props.value.body[0],
            action: props.value.body[1].action[0].substring(8)+':'+props.value.body[1].action[1],
            link: flink
        };

        this.setValue = this.setValue.bind(this);
        this.setArea = this.setArea.bind(this);
    }

    setArea() {
        if (this.mounted) {
            //console.log("Alert", 'mounted');
            const { width, height, value } = this.state;

            let lines = ( value.length / 10 + 1);
            if( lines > 4 ) lines = 4;
            const element = this.ghost;

            this.setState({
                height: (DefaultHeight*lines),
                width: element.clientWidth
            });
        }
    }


    inFocus(ev: React.FocusEvent<HTMLInputElement>){
        if (ev.currentTarget.parentNode) {
            console.log("Alert", 'focused');
        }
  
    }

    outFocus(ev: React.FocusEvent<HTMLInputElement>){
        if (ev.currentTarget.parentNode) {
            console.log("Alert", 'focus out');
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.setArea();
        window.setTimeout(() => this.setArea(), 1);
    }

    setValue(event: { target: { value: any } }) {
        const { value } = event.target;
        this.setState({ value });

        this.props.onClick(this.props.key,7,value);
    }

    inputField() {
        const { width, height, value } = this.state;
        //console.log("Alert", value);
        return (
            <div className="inline-flex">
                <Styled.TextArea
                    style={{
                        width,
                        height,
                    }}
                    value={value}
                    onChange={this.setValue}
                    onKeyUp={this.setArea}
                    readOnly={this.props.edit?false:true} 
                />
            </div>
        );
    }

    ghostField() {
        return (
            <Styled.TextAreaGhost
                className="enum-ghost"
                defaultValue={this.state.value}
                ref={c => (this.ghost = c)}
                aria-hidden="true"/>
        );
    }

    openLink = () => {
        if( this.state.link !== '' ){
            const url = '/flow/'+this.state.link;
             //+"/"+title; //this.props.node.id;
            let wo = window.open(url, '_blank',"height=500,width=800");
            //wo.onclose = this.getClose; 
            //alert(wo);
        }
    };

    render() {


        return (
            <Styled.Container>
                <Styled.LabelTitleGhost>{this.state.action}</Styled.LabelTitleGhost>
                <br/>
                {this.state.link !== ''&&<Styled.LabelTitleGhost> <a onClick={this.openLink}>open</a></Styled.LabelTitleGhost>}<br/>
                {this.inputField()}
                {this.ghostField()}
            </Styled.Container>
        );
    }
}

/*
               

*/