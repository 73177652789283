import "regenerator-runtime/runtime.js";

import * as React from "react";

//import * as ReactDOM from 'react-dom';
import {createRoot} from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ApolloProvider } from '@apollo/client';
import createApolloClient from './hoc/ApolloProvider';

import App            from './containers/App'

import LogRocket from 'logrocket';
LogRocket.init('ov2fxq/test');

/*
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Router><div>
        <Routes>
          <Route path="/" element={<App/>} />
          <Route path="/:type/:id/:index" element={<App/>}/>
          <Route path="/:type/:id" element={<App/>}/>
          <Route path="/:type" element={<App/>}/>
        </Routes></div>
    </Router>
  </React.StrictMode>)


ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={createApolloClient} >
    <Router><div>
        <Routes>
          <Route path="/" element={<App/>} />
          <Route path="/:type/:id/:index" element={<App/>}/>
          <Route path="/:type/:id" element={<App/>}/>
          <Route path="/:type" element={<App/>}/>
        </Routes></div>
    </Router>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
*/
//ReactDOM.render(<App />, document.getElementById("root"));

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
    <ApolloProvider client={createApolloClient} >
    <Router><div>
        <Routes>
          <Route path="/" element={<App/>} />
          <Route path="/:type/:id/:index" element={<App/>}/>
          <Route path="/:type/:id" element={<App/>}/>
          <Route path="/:type" element={<App/>}/>
        </Routes></div>
    </Router>
    </ApolloProvider>
    </React.StrictMode>);
