import React from "react";
import * as ReactDOM from 'react-dom';
import styled from "styled-components";
import { Icon } from "./Icon";
// MARK: Constants

const DefaultWidth = 65;

// MARK: Types



// MARK: Styles

const Styled = {
    Container: styled.div`
        position: relative;
        text-align: left;
        width: 100%;
        margin-bottom: 6px;
    `,
    LabelTitle: styled.input`
        background: #ffffff;
        border-radius: 26px;
        border-width: 2px;
        border-style: solid;
        border-color: rgb(0, 177, 169);
        color: rgba(0, 0, 0, 0.87);
        cursor: pointer;
        display: inline-block;
        font-size: 12px;
        font-weight: normal;
        font-family: "Roboto", sans-serif;
        flex-direction: row;
        letter-spacing: normal;
        line-height: 14px;
        margin: 0;
        margin-left: 20px;
        padding: 0;
        text-align: center;
        text-indent: 0px;
        text-rendering: auto;
        text-shadow: none;
        text-transform: none;
        word-spacing: normal;
    `,
    LabelTitleGhost: styled.div`
        opacity: 0.3;
        display: block;
        white-space: pre-wrap;
        word-wrap: break-word;
        visibility: hidden;
        position: absolute;
        top: 0;
        margin: 0;
        margin-left: 20px;
        padding: 4px;
    `
/*
                <Styled.PrimaryAction>
                <Icon id={'1'} /><Icon id={'2'}/><Icon id={'3'}/><Icon id={'4'}/>
                </Styled.PrimaryAction>
*/
};

// MARK: Component
// eslint-disable-next-line @typescript-eslint/no-namespace
declare module LabelTitle {
    export type Props = {
        value: string;
        width: number;
        edit: boolean;
        key:string;
        onClick?: (key:any,action:any,data:any) => void;
    };

    export type State = {
        value?: string;
        width: number;
    };
}

export class LabelControl extends React.Component<
    LabelTitle.Props,
    LabelTitle.State
> {
    mounted!: boolean;
    capsule: any;
    ghost: any;
    state = {
        width: DefaultWidth,
        value: null
    };

    constructor(props: LabelTitle.Props) {
        super(props);

        this.state = {
            width: DefaultWidth,
            value: props.value
        };

        this.setValue = this.setValue.bind(this);
        this.setWidth = this.setWidth.bind(this);
    }

    setWidth() {
        if (this.mounted) {
            //console.log("Alert", 'mounted');
            const element = this.ghost;
            let width = element.clientWidth * 1.2;
            this.setState({
                width: width
            });
        }
    }

    inFocus(ev: React.FocusEvent<HTMLInputElement>){
        if (ev.currentTarget.parentNode) {
            console.log("Alert", 'focused');
        }
  
    }

    outFocus(ev: React.FocusEvent<HTMLInputElement>){
        if (ev.currentTarget.parentNode) {
            console.log("Alert", 'focus out');
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.setWidth();
        window.setTimeout(() => this.setWidth(), 1);
    }

    setValue(event: { target: { value: any } }) {
        const { value } = event.target;
        this.setState({ value });
        this.props.onClick(this.props.key,5,value);
    }

    inputField() {
        const { width, value } = this.state;
        //console.log("Alert", value);
        return (
            <div className="inline-flex">
                <Styled.LabelTitle
                    style={{
                        width
                    }}
                    value={value}
                    onChange={this.setValue}
                    onKeyUp={this.setWidth}
                    readOnly={this.props.edit?false:true} 
                />
            </div>
        );
    }

    ghostField() {
        return (
            <Styled.LabelTitleGhost
                className="enum-ghost"
                ref={c => (this.ghost = c)}
                aria-hidden="true">
                {this.state.value}
            </Styled.LabelTitleGhost>
        );
    }

    render() {


        return (
            <Styled.Container>
                {this.inputField()}
                {this.ghostField()}
            </Styled.Container>
        );
    }
}
