// source : https://github.com/retejs/react-render-plugin/blob/master/src/index.jsx

import React from "react";

import Rete from "rete";
//import { BodyControl } from "../Controls/BodyControl";
//import { LinkControl } from "../Controls/LinkControl";
//import { StatusControl } from "../Controls/StatusControl";
import { actionSocket } from "../Sockets/Sockets";

import { LabelControl } from "../Views/Label";
import { EnumOptionControl } from "../Views/EnumOption";


import { PrimaryActionControl } from "../Views/PrimaryAction";
import { Node, Socket, Control } from "rete-react-render-plugin";
import styled from "styled-components";
import { Icon } from "../Views/Icon";
import { Dataarea } from "../Views/DataEdit";

import { Output } from "rete";
import { isNumber } from "util";

import { useReactiveVar } from "@apollo/client";
import { cardLink, cardLogic } from "../../../hoc/ApolloProvider";
import ViewTypePicker from "../Views/ViewTypePicker";
import ActionEditor from "../Views/ActionEditor";
import SwitchEditor from "../Views/SwitchEditor";
import BgEditor from "../Views/BgEditor";

import { StatusControl } from "../Views/StatusControl";
import { BodyControl } from "../Views/BodyControl";
import { ActionControl } from "../Views/ActionControl";
import { LinkControl } from "../Views/LinkControl";

const Styled = {
    Container: styled.div`
        position: relative;
        text-align: right;
        margin: 6px;
    `,
    PrimaryAction: styled.button`
        position: absolute;
        zIndex: 100;
        align-self: flex-end;
        background: linear-gradient(
            119.16deg,
            rgb(0, 118, 129) 0%,
            rgb(0, 132, 132) 100%
        );
        border: none;
        border-radius: 26px;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 8px,
            rgba(0, 0, 0, 0.06) 0px 1px 4px;
        box-sizing: border-box;
        color: white;
        cursor: pointer;
        display: flex;
        height: 46px;
        margin: 0;
        margin-top: -53px;
        margin-right: 0px;
        margin-bottom: 0;
        min-width: 46px;
        overflow: visible;
        padding: 9px 10px 12px 10px;
        -webkit-appearance: button;
        -webkit-box-pack: justify;
    `,
    Divider: styled.hr`
        border: 1px solid #bbbbbb;
        border-radius: 2px;
        margin-left: 3%;
        width: 25%;
        position: absolute;
    `
/*
                <Styled.PrimaryAction>
                <Icon id={'1'} /><Icon id={'2'}/><Icon id={'3'}/><Icon id={'4'}/>
                </Styled.PrimaryAction>
*/
};

// change to React.memo()
// https://stackoverflow.com/questions/66590082/how-to-prevent-re-rendering-of-components-that-have-not-changed
/**
 * const Button = React.memo(() => {
  console.log("Button Rendered!");
  window.alert("Button Rendered");
  return <button onClick="">Press me</button>;
});
 * 
 */

export class ReactNode extends Node {
    
    props: {
        node: any;
        editor: any;
        bindSocket: any;
        bindControl: any;
    };
    state: {
        outputs:any;
        controls:any; 
        inputs:any; 
        selected:any;
        edit:boolean;
        rand:string;
        data:any;
    };

    //edit:boolean = false;
    //14 dataedit:boolean = false;
    message:string = "";
   
    constructor(props) {
        super(props);
        //console.log( this.props.node.id);
        this.state = { outputs: null, controls: null, inputs: null, selected: null, edit:false,rand:"",data:{imgSrc:""}};
    }


    componentDidMount() {
        //console.log( this.props.node.id);
        if (typeof this.props.node.id === 'number') {
            this.props.node.id = this.props.node.id.toString();
        }
        if( this.props.node.data.body.length === 1 )
            this.props.node.data.body.push({"type":1, "action": [],"rules": []});
        if( this.props.node.data.body.length === 2 )    
            this.props.node.data.body.push(1);
    }

    findkey( pre){
        let i = 1 ;
        let key = ""
        while(true){
            key = pre+i;
            if( this.state.outputs.findIndex(item => item.key === key) === -1)
                return i;
            i++;
        }
    }    

    // view mode ------------------------------
    onOpen = (id:any) => {
        //const cardLinkValue = useReactiveVar(cardLink);
        if( this.props.node.id.length > 10){
            //const url = 'https://liflo.io/link/'+cardLink().flowid+"/"+this.props.node.id;
            //const url = 'http://localhost:3006/card/'+this.props.node.id;
            const url = ((window.location.host.indexOf('localhost') !== -1) ? "http://localhost:3006":"https://fg.liflo.io")+'/card/'+this.props.node.id;

            window.open(url, '_blank',"height=800,width=550");    
        }
        else
            alert("First 'card generate'");
    };

    // view mode ------------------------------
    onCaseEdit = () => {
        //const cardLinkValue = useReactiveVar(cardLink);
        if( this.props.node.id.length > 10){
            const url = '/image/'+this.props.node.id; //+"/"+title; //this.props.node.id;
            window.open(url, '_blank',"height=800,width=550");    
        }
        else
            alert("First 'card generate'");
    };

    getClose(msg) {
        alert(msg)
    }

    onBgEdit = () => {
        if( this.props.node.id.length > 10){
            const url = '/bg/'+this.props.node.id; //+"/"+title; //this.props.node.id;
            let wo = window.open(url, '_blank',"height=800,width=550");
            //wo.onclose = this.getClose; 
            //alert(wo);
        }
        else
            alert("First 'card generate'");
    };

    // edit mode ------------------------------
    onClick = (id:any) => {
        //alert('click edit icon :'+id);
        this.state.edit = !this.state.edit; 
        //@ts-ignore
        this.setState({ selected:'selected' }); 
        //this.state.edit = true;
    };

//---------------------------    
    onDataEdit = () => {
        //alert('data edit :'+this.props.node.data.body[0]);
        //14 this.dataedit = !this.dataedit;
        this.state.controls.map(control => {
            if( control instanceof BodyControl){
                //control.setReadOnly(!(this.edit)); //14  && this.dataedit));
                //control.renderControl();
            }
            else if( control instanceof LinkControl){
            } 
        })
        //@ts-ignore
        //this.setState({ selected:'' });
        //@ts-ignore
        this.setState({outputs:this.state.outputs}); 
        this.props.node.update();
    };
/*
    onUpdateMessage = () => {
        this.dataedit = !this.dataedit;
        //@ts-ignore
        this.setState({ selected:'selected' }); 
    }

    _remove<T extends any>(list: Map<string, T>, key: string){
        if (list.has(key))
            list.delete(key);
    }

                        <div>
                            <Control
                                className="control"
                                key={control.key}
                                control={control}
                                readonly={this.edit}
                                innerRef={bindControl}
                            />
                            {this.edit === true && !control.props.id.includes('_STATUS') && (
                                this.dataedit ? (
                                    <div>
                                    <div style={{marginLeft:'80%',marginBottom:'20px'}}>
                                    <Styled.PrimaryAction>
                                        <Icon  onClick={this.onUpdateMessage} id={'6'}/>
                                        <Icon  onClick={this.onDataEdit} id={'7'}/>
                                    </Styled.PrimaryAction>
                                    </div>
                                    <div style={{marginLeft:'2%',marginBottom:'20px', width: '300px'}}>
                                        <Dataarea value={this.props.node.data.body[0]} label={this.props.node.data.label} onChange={this.changeData}/>
                                    </div>
                                    </div>
                                ):(
                                    <div style={{marginLeft:'80%',marginBottom:'20px'}}>
                                    <Styled.PrimaryAction>
                                        <Icon  onClick={this.onDataEdit} id={'5'}/>
                                    </Styled.PrimaryAction>
                                    </div>
                                ))
                            }
                        </div>

    changeData = (e) => {
        console.log('change data :',e.target.value);
        this.message = e.target.value;
    };

    onUpdateMessage = () => {
        this.props.node.data.body[0] = this.message;
        this.dataedit = !this.dataedit;
        let ctrl = this.state.controls[this.state.controls.findIndex(item => item.key === 'FEVER_QUESTION_BODY')];
        ctrl.putData('FEVER_QUESTION_BODY',this.message);
        //const args: any = 'arguments';
        //ctrl.putData.apply(ctrl, args as any);
        ctrl.update();
        //@ts-ignore
        this.setState({ selected:'selected' });        
        //this.props.node.update(); 
    }
*/    
//---------------------------------------
    onInputEdit = (key:any,action:any, data:any) => {
        this.props.node.data.label = data;
        this.props.node.update();
    }

    onOutputEdit = (key:any,action:any, data:any) => {
        //alert('output edit - remove:'+key);
        //this.state.outputs.splice(this.state.outputs.findIndex(item => item.key === key), 1);
        let rand = '';
        if( action === 1){
            let pre = "Connect_"
            let nid = this.findkey(pre);
            let output = {id: pre+nid, type: "CONNECT_ACTION", text: "연결_"+nid, img: "",outputs:[], pt:1.0,wt:1.0};
            this.props.node.addOutput( new Rete.Output(output.id, output.text, actionSocket, false));
            //this.props.node.data.actions.unshift(output); // add to beginning
            this.props.node.data.actions.push(output); // add to end

        }
        else if( action === 2){  // up 
        }            
        else if( action === 3){  // down
        }            
        else if( action === 4){ // remove
            let out = this.state.outputs[this.state.outputs.findIndex(item => item.key === key)];
            for (const conn of [...out.connections])
                this.props.editor.removeConnection(conn)
            this.props.node.removeOutput(out) //this.state.outputs.findIndex(item => item.key === key));
            this.props.node.data.actions.splice(this.props.node.data.actions.findIndex(item => item.id === key),1);
        }
        else if( action === 5){ // change action title
            this.props.node.data.actions[this.props.node.data.actions.findIndex(item => item.id === key)].text = data;
        }
        else if( action === 6){ // change outputs
            this.props.node.data.actions[this.props.node.data.actions.findIndex(item => item.id === key)].outputs = data;
        }
        else if( action === 7){ // change 
            this.props.node.data.body[0] = data;
        }
        else if( action === 8){ // change 
            //this.props.node.data.body[1].action[0] = data;
        }
        //@ts-ignore
        this.setState({outputs:this.state.outputs});

        this.props.node.update();
        //this.state.outputs.delete(key);
    };

    onChangeAction = (body:any) => {
        //this.props.onClick(this.props.vkey,6,vt);
        // this.props.pt
        //alert(body[0]);
        this.props.node.data.body = body;
        if( body[1].type === 4 && this.props.node.data.label === '제목을 입력합니다.')
            this.props.node.data.label = body[1].action[0];
        //@ts-ignore
        
        this.setState({outputs:this.state.outputs, rand:''+Math.random()});
        this.props.node.update();
    };

    onChangeBg = (data:any) => {
    };

    onChangeViewType = (vt) => {
        //this.props.onClick(this.props.vkey,6,vt);
        // this.props.pt
        this.props.node.data.body[2] = vt;
    };

    render() {
        const { node, bindSocket, bindControl } = this.props;
        const { outputs, controls, inputs, selected, rand} = this.state;
        //console.log('render :'+node.id);

        return (
            <div className={`node ${selected}`}>
                <Styled.PrimaryAction>
                    <Icon onClick={this.onOpen} id={'8'}/>
                    <Icon onClick={this.onClick} id={'5'}/>
                </Styled.PrimaryAction>
                {/* Inputs */}
                {inputs.length === 0 ? (
                    <div className="input" key={node.id}>
                        <div className="node-title">{node.data.label}</div>
                    </div>
                ) : (
                    inputs.map((input,index) => (
                        <div className="input" key={input.key+index} style={{zIndex:1}}>
                            <Socket
                                type="input"
                                socket={input.socket}
                                io={input}
                                innerRef={bindSocket}
                            />
                            {!input.showControl() && (
                                //<div className="input-title">{node.data.label}</div>  + (this.edit ? Math.random():'')
                                <div style={{width:'82%'}}>
                                <LabelControl key={node.id } value={node.data.label} width={100} edit={this.state.edit} onClick={this.onInputEdit}/>
                                </div>
                            )}
                            {input.showControl() && (
                                <Control
                                    className="input-control"
                                    control={input.control}
                                    innerRef={bindControl}
                                />
                            )}

                        </div>
                    ))
                )}
                {/* Controls */}
                {
                    controls.map((control,index) => {
                        control.props.readonly = !this.state.edit;

                        let mode = 0; // control.props.id.includes('_STATUS')
                        if( control.props.name === 'SwitchCard' || control.props.name === 'StartCard' || control.props.name === 'EndCard')
                            mode = 1;
                        else if( control.props.name === 'ActionCard' )
                            mode = 2;
                        else if( control.props.name === 'InfoCard')
                            mode = 3;
                        else if( control.props.name === 'LinkCard')
                            mode = 4;

                        if( control.props.id.includes('_STATUS') === true )
                            mode = 0;

                        return(
                        <Styled.Container key={control.key+index}>
                            { mode === 0 && (
                                <StatusControl key={node.id } value={node.data.label} width={100} edit={this.state.edit} onClick={this.onInputEdit}/>
                            )}
                            {(this.state.edit === true && mode > 0) && (
                                    <div  style={{position:"absolute", zIndex:20, marginLeft:'103%',marginBottom:'10px'}}>
                                        <Styled.PrimaryAction>
                                            {this.state.edit && (<SwitchEditor onChange={(body:any)=>this.onChangeAction(body)} action={this.props.node.data.body}/>)}
                                            {this.state.edit && (this.props.node !== undefined)  &&(<BgEditor onChange={(body:any)=>this.onChangeBg(body)} node={this.props.node} data={this.state.data}/>)}
                                            <Icon onClick={this.onCaseEdit} id={'10'}/>
                                        </Styled.PrimaryAction>                                    
                                    </div>
                            )}
                            {mode === 1 && (
                                <BodyControl key={node.id + rand} value={node.data} width={100} edit={this.state.edit} onClick={this.onOutputEdit}/>
                            )}
                            {mode === 2 && (
                                <ActionControl key={node.id + rand} value={node.data} width={100} edit={this.state.edit} onClick={this.onOutputEdit}/>
                            )}
                            {mode === 4 && (
                                <LinkControl key={node.id + rand} value={node.data} width={100} edit={this.state.edit} onClick={this.onOutputEdit}/>
                            )}
                            {mode === 3 && (
                                <Control
                                className="control"
                                key={control.key+ (this.state.edit ? Math.random():'')}
                                control={control}
                                readonly={false}//!(this.state.edit)} //14 && this.dataedit)} 
                                innerRef={bindControl}/>
                            )}
                        </Styled.Container>
                    )
                    })
                }
                {/* Spacer */}
                <div className="spacer" key={`spacer`}>
                </div>
                {/* Outputs */}
                <div className="actions" key={`actions`}>
                    <Styled.Divider />
                    {outputs.map((output,index) => (
                        <div className="output" key={output.key+index}>
                            <div className="output actions">
                                {this.generateActions(node, output.key)}
                            </div>
                            <div className="output sockets">
                                <Socket
                                    type="output"
                                    socket={output.socket}
                                    io={output}
                                    innerRef={bindSocket}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    generateActions(node, id) {
        const action = node.data.actions.find(function(act) {
            return act.id === id;
        });
        if( action === undefined)
            return;
        
        //console.log(    action.outputs);    
        if( action.outputs === undefined || action.outputs.length === 0 )
            action.outputs = [{output:cardLogic().outputKeys[0],pt:1,wt:1}];
        return <EnumOptionControl key={id} vkey={id} actionType={action.type} value={action.text} outputs={action.outputs} pt={action.pt} width={100} edit={this.state.edit} onClick={this.onOutputEdit}/>;
    }
}

/*
                            <Control
                                className="control"
                                key={control.key+ (this.state.edit ? Math.random():'')}
                                control={control}
                                readonly={false}//!(this.state.edit)} //14 && this.dataedit)} 
                                innerRef={bindControl}
                            />*/