// editor : https://github.com/pixelcities/datagarden
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Icon } from "./Icon";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { IconButton, Input, List, ListItem, ListItemText, Stack, Tab, Tabs, Typography } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import CardMedia from '@mui/material/CardMedia';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { cardLogic } from '../../../hoc/ApolloProvider';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box component="div"  sx={{ p: 3 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



export default function SwitchEditor( props) {
    const [open, setOpen] = React.useState(false);

    const [viewtype, setViewType] = React.useState<number | string>(props.action[2]===undefined?0:props.action[2]);
    const [reporttype, setReportType] = React.useState<number | string>(props.action[1].type===undefined?1:props.action[1].type);
    const [actiontype, setActionType] = React.useState<number | string>(props.action[1].type===undefined?1:props.action[1].type);
    
    const [value, setValue] = React.useState(props.action[1].cat === 4? 1:0);

    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);

    const [outputs, setOutputs] = React.useState(props.action[1].rules === ""?["plus"]:props.action[1].rules);
    const [output, setOutputValue] = React.useState('');
      
    React.useEffect(()=>{
      if( actiontype === 4 && props.action[1].action[0] === '' )
        props.action[1].action[0] = '../Animation/BullsEye';
      if( actiontype === 9 && props.action[1].action[0] === '' )
        props.action[1].action[0] = '../Exam/Memory';
    },[actiontype])


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
      
    const handleTitle = (event: any) => {
      //alert(event.currentTarget.value);
      props.action[0] = event.currentTarget.value;
      //setType(Number(event.target.value) || '');
    };

    const handleViewType = (event: SelectChangeEvent<typeof viewtype>) => {
      props.action[2] = Number(event.target.value);
      setViewType( props.action[2] || '');
    };

    const handleReportType = (event: SelectChangeEvent<typeof reporttype>) => {
      props.action[1].type = Number(event.target.value);
      setReportType( props.action[1].type || '');
    };

    const handleActionType = (event: SelectChangeEvent<typeof actiontype>) => {
      props.action[1].type = Number(event.target.value);
      setActionType( props.action[1].type || '');
      if( props.action[1].type !== 5 ){
        props.action[1].action[0] = "";
      }
    };

    const handleActionData = (event: any) => {
      //alert(event.currentTarget.value);
      props.action[1].action[0] = event.currentTarget.value;
      props.action[1].type = actiontype;
      //setType(Number(event.target.value) || '');
    };
    const handleActionAudio = (event: any) => {
      //alert(event.currentTarget.value);
      props.action[1].action[1] = event.currentTarget.value;
      props.action[1].type = actiontype;
      //setType(Number(event.target.value) || '');
    };


    const handleAnimation = (event: SelectChangeEvent) => {
      props.action[1].action[0] = event.target.value;
    };
    const handleExamination = (event: SelectChangeEvent) => {
      props.action[1].action[0] = event.target.value;
    };
    const handleForm = (event: SelectChangeEvent) => {
      props.action[1].action[0] = event.target.value;
    };
    const handleFormSub = (event: SelectChangeEvent) => {
      props.action[1].action[1] = event.target.value;
    };
    const handlePort = (event: SelectChangeEvent) => {
      props.action[1].action[0] = event.target.value;
    };
    const handlePortData = (event: any) => {
      props.action[1].action[1] = event.currentTarget.value;
    };
    const handleSrc = (event: any) => {
      props.action[1].action[2] = event.currentTarget.value;
    };
    const handleMenuName = (event: any) => {
      props.action[1].action[3] = event.currentTarget.value;
    };

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
      if (reason !== 'backdropClick') {
        setOpen(false);
        if( props.action[1].cat === 1 ){
          props.action[1].rules = outputs;
          cardLogic().outputKeys = outputs;
        }
        props.onChange(props.action);
      }
    };

  const addOutput = (event: any) => {
    if( outputs.find( out => out === output) === undefined)
      setOutputs([...outputs, output]);
  }

  const removeOutput = (event:any, idx: any) => {
    var filtered = outputs.filter(function(value, index, arr){ 
      return index !== idx;
    });
    setOutputs(filtered);
  }

  // body: ["", {"type": 1, "action":["https://cam.liflo.io/c/:cid"],"rules":""}, 2],
    return (
      <div>
        <Icon onClick={handleClickOpen} id={'5'}/>
        {open && <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
          <DialogTitle>Card Editor</DialogTitle>
          <DialogContent>
            <Box component="div"  sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="View Template" {...a11yProps(0)} />
                { (props.action[1].cat === 1 || props.action[1].cat === 3) && <Tab label="Outputs" {...a11yProps(1)} /> }
                { props.action[1].cat === 2 && <Tab label="End" {...a11yProps(1)} /> }
                { props.action[1].cat === 4 && <Tab label="Action" {...a11yProps(1)} /> }
                <Tab label="Setting" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <CardMedia component="img" width="150" image="/viewtype2.png" alt="Paella dish"/>
              <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <FormControl sx={{ m: 1, minWidth: 120}} size="small">
                  <InputLabel htmlFor="viewType">View Type</InputLabel>
                  <Select
                    native
                    label="View Type"
                    defaultValue={props.action[2]}
                    onChange={handleViewType}
                    size="small"
                    inputProps={{
                      id: 'viewType',
                    }}
                  >
                    <option value={1}>normal</option>
                    <option value={2}>image</option>
                    <option value={3}>select</option>
                    <option value={4}>select-가로허용</option>
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120, margin:0}} margin="normal" size="small">
                  
                    <Typography variant="caption" color='gray'>Content</Typography>
                    <TextareaAutosize
                      maxRows={4}
                      aria-label=""
                      placeholder=""
                      defaultValue={props.action[0]}
                      onChange={handleTitle}
                      id="imageText"
                      style={{ width: 200, margin: 1 }}/>
                  
                </FormControl>
              </Box>
            </TabPanel>
            { props.action[1].cat === 1 && <TabPanel value={value} index={1}>
            <Stack direction="row" spacing={2}>
              <Input defaultValue="" onChange={(e)=>setOutputValue(e.target.value as string)} inputProps={{ 'aria-label': 'description' }} />
              <Button variant="contained" onClick={addOutput} endIcon={<AddIcon />}>Add</Button>
            </Stack>
            <List dense={dense}>
              {outputs.map((output,index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton onClick={(e)=>removeOutput(e,index)} edge="end" aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={output}
                    secondary={secondary ? 'Secondary text' : null}
                  />
                </ListItem>
              ))}
            </List>  
            </TabPanel>}
            { props.action[1].cat === 2 && <TabPanel value={value} index={1}>
            <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <FormControl sx={{ m: 1, minWidth: 120}} size="small">
                <InputLabel htmlFor="reportType">Action Type</InputLabel>
                <Select
                  native
                  label="Report Type"
                  defaultValue={props.action[1].type}
                  onChange={handleReportType}
                  size="small"
                  inputProps={{
                    id: 'reportType',
                  }}
                >
                  <option value={0}>Card End</option>
                  <option value={1}>Assessment Report</option>
                  <option value={2}>User</option>
                </Select>
              </FormControl>
            </Box>
            </TabPanel>}
            { props.action[1].cat === 4 && <TabPanel value={value} index={1}>
            <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <FormControl sx={{ m: 1, minWidth: 120}} size="small">
                <InputLabel htmlFor="actionType">Action Type</InputLabel>
                <Select
                  native
                  label="Action Type"
                  defaultValue={props.action[1].type}
                  onChange={handleActionType}
                  size="small"
                  inputProps={{
                    id: 'actionType',
                  }}
                >
                  <option value={1}>image</option>
                  <option value={2}>iframe</option>
                  <option value={3}>schedule</option>
                  <option value={4}>animation</option>
                  <option value={5}>video chat</option>                 
                  <option value={6}>examinee</option>
                  <option value={7}>plus</option>
                  <option value={8}>user</option>
                  <option value={9}>Examination</option>
                  <option value={10}>Form</option>
                  <option value={11}>Port</option>
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 120, margin:0}} margin="normal" size="small">
                { actiontype === 1 && (
                <div>
                  <Typography variant="caption" color='gray'>Content</Typography>
                  <TextareaAutosize
                    maxRows={4}
                    aria-label=""
                    placeholder=""
                    defaultValue={props.action[0]}
                    onChange={handleTitle}
                    id="imageText"
                    style={{ width: 200, margin: 1 }}/>
                  <Typography variant="caption" color='gray'>Audio Address</Typography>
                  <TextareaAutosize
                    maxRows={4}
                    aria-label="maximum height"
                    placeholder="Maximum 4 rows"
                    defaultValue={props.action[1].action[1]}
                    onChange={handleActionAudio}
                    id="audio"
                    style={{ width: 200, margin: 1 }}/>
                </div>)}
                { actiontype === 2 && (
                <div>
                  <Typography variant="caption" color='gray'>Web Address</Typography>
                  <TextareaAutosize
                    maxRows={4}
                    aria-label="maximum height"
                    placeholder="Maximum 4 rows"
                    defaultValue={props.action[1].action[0]}
                    onChange={handleActionData}
                    id="iframe"
                    style={{ width: 200, margin: 1 }}/>
                  <Typography variant="caption" color='gray'>Audio Address</Typography>
                  <TextareaAutosize
                    maxRows={4}
                    aria-label="maximum height"
                    placeholder="Maximum 4 rows"
                    defaultValue={props.action[1].action[1]}
                    onChange={handleActionAudio}
                    id="audio"
                    style={{ width: 200, margin: 1 }}/>
                  </div>)}
                { actiontype === 4 && ( // import '../Animation/MonkeyLadder'
                <div>
                <InputLabel htmlFor="animationType">Animation Type</InputLabel>
                <Select
                    native
                    label="Animation Type"
                    defaultValue={props.action[1].action[0]}
                    onChange={handleAnimation}
                    size="small"
                    inputProps={{
                      id: 'animationType',
                    }}
                    >
                    <option value={'../Animation/BullsEye'}>Bull's Eye</option>
                    <option value={'../Animation/Triangle'}>Triangle</option>
                    <option value={'../Animation/MonkeyLadder'}>MonkeyLadder</option>
                    <option value={'../Animation/Hanoi'}>Tower of Hanoi</option>
                    <option value={'../Animation/Rotations'}>Rotations</option>
                    <option value={'../Animation/Audio'}>Audio</option>
                    <option value={'../Animation/Dominos'}>Dominos</option>
                    <option value={'../Animation/Breathing'}>Breathing</option>
                    <option value={'../Animation/Donut'}>Donut</option>
                    <option value={'../Animation/Cannon'}>Cannon</option>
                    <option value={'../Animation/Bezier'}>Bezier</option>
                    <option value={'../Animation/Calibration'}>Calibration</option>
                    <option value={'../Animation/Model'}>Model</option>
                  </Select>
                  
                  </div>) 
                }
                { actiontype === 9 && ( // import '../Exam/Memory'
                <div>
                <InputLabel htmlFor="examinationType">Examination Type</InputLabel>
                <Select
                    native
                    label="Examination Type"
                    defaultValue={props.action[1].action[0]}
                    onChange={handleExamination}
                    size="small"
                    inputProps={{
                      id: 'examinationType',
                    }}
                    >
                    <option value={'../Exam/Memory'}>Memory</option>
                    <option value={'../Exam/Burger'}>Burger</option>
                    <option value={'../Exam/Intro'}>Intro</option>
                    <option value={'../Exam/MemoryChild'}>Child - Memory Exam</option>
                    <option value={'../Exam/EmotionChild'}>Child - Emotion Exam</option>
                    <option value={'../Exam/RotationChild'}>Child - Rotation Exam</option>
                    <option value={'../Exam/MotorChild'}>Child - Motor Exam</option>
                    <option value={'../Exam/SequenceChild'}>Child - Sequence Exam</option>
                    <option value={'../Exam/NBackChild'}>Child - NBack Exam</option>
                    <option value={'../Exam/EmotionAdult'}>Adult - Emotion Exam</option>
                    <option value={'../Exam/RotationAdult'}>Adult - Rotation Exam</option>
                    <option value={'../Exam/MotorAdult'}>Adult - Motor Exam</option>
                    <option value={'../Exam/SequenceAdult'}>Adult - Sequence Exam</option>
                    <option value={'../Exam/NBackAdult'}>Adult - NBack Exam</option>
                    <option value={'../Exam/Puzzle'}>Puzzle</option>
                  </Select></div>) 
                }
                { actiontype === 10 && ( // import '../Form/Student'
                <>
                <div>
                  <InputLabel htmlFor="formType">Form Type</InputLabel>
                  <Select
                      native
                      label="Form Type"
                      defaultValue={props.action[1].action[0]}
                      onChange={handleForm}
                      size="small"
                      inputProps={{
                        id: 'formType',
                      }}
                      >
                      <option value={'../Form/Base'}>Base</option>
                      <option value={'../Form/BaseCNT'}>CNT Games</option>

                      <option value={'../Form/User'}>User w/ Login</option>
                      <option value={'../Form/UserInfo'}>User Information</option>
                      <option value={'../Form/Task'}>Task</option>
                      <option value={'../Form/Video'}>Video</option>
                      <option value={'../Form/VideoRecord'}>Video Record</option>
                      <option value={'../Form/VideoChat'}>Video Chat</option>

                      <option value={'../Form/SPlay'}>Student Play</option>
                      <option value={'../Form/TPlay'}>Teacher Play</option>

                      <option value={'../Form/Reservation'}>Reservation</option>
                      <option value={'../Form/ExamHistory'}>Exam History</option>

                      <option value={'../Form/Student'}>Student</option>
                      <option value={'../Form/Teacher'}>Teacher</option>
                      <option value={'../Form/ResultMain'}>Result-Main</option>
                      <option value={'../Form/ResultQA'}>Result-QA</option>
                      <option value={'../Form/ResultVideo'}>Result-Video</option>
                      <option value={'../Form/ResultType'}>Result-Type</option>
                      <option value={'../Form/ResultAnalysys'}>Result-Analysis</option>
                  </Select>
                  </div>
                  {props.action[1].action[0] === '../Form/Video' &&
                      <div> 
                        <Typography variant="caption" color='gray'>Video</Typography>
                        <TextareaAutosize
                        maxRows={4}
                        aria-label="maximum height"
                        placeholder="video id/url/[user]"
                        defaultValue={props.action[1].action[1]}
                        onChange={handlePortData}
                        id="iframe"
                        style={{ width: 200, margin: 1 }}/>
                        <Typography variant="caption" color='gray'>Thumbnail</Typography>
                        <TextareaAutosize
                        maxRows={4}
                        aria-label="maximum height"
                        placeholder="video id/url/[user]"
                        defaultValue={props.action[1].action[2]}
                        onChange={handleSrc}
                        id="iframe"
                        style={{ width: 200, margin: 1 }}/>
                      </div>}
                  {props.action[1].action[0] === '../Form/BaseCNT' &&
                      <div>
                      <InputLabel htmlFor="formSub">Sub Form</InputLabel>
                      <Select
                          native
                          label="Sub Form"
                          defaultValue={props.action[1].action[1]}
                          onChange={handleFormSub}
                          size="small"
                          inputProps={{
                            id: 'formSub',
                          }}
                          >
                          <option value={'ExBase'}>Base</option>
                          <option value={'ExBaseSVG'}>Base-svg</option>
                          
                          <option value={'ExBM01Target'}>Target Ball (BM-01)</option>
                          <option value={'ExCC01GoNoGo'}>Go - No Go (CC-01)</option>
                          <option value={'ExCA01Flanker'}>Flanker (CA-01)</option>
                          <option value={'ExCM01CorsiBlock'}>Corsi Block (CM-01)</option>
                          <option value={'ExCE01HanoiBubble'}>Tower of London (CE-01)</option>
                          <option value={'ExCS01Rotation'}>Rotation (CS-01)</option>
                          <option value={'ExSC01ToMind'}>Theoty of Mind (SC-01)</option>

                          <option value={'ExNBackChild'}>n-back child (CM-02)</option>
                          <option value={'ExNBack'}>NBack-Drum</option>
                          <option value={'ExMoleHole'}>MoleHole</option>
                          <option value={'ExCharacter'}>Character</option>
                          <option value={'ExMusic'}>Music</option>
                          <option value={'ExTracker'}>Tracker</option>
                          <option value={'ExMotion'}>Motion</option>
                          <option value={''}>--ready yet-----</option>
                          <option value={'ExDrum'}>Drum</option>
                          <option value={'ExAnimation'}>Animation</option>
                      </Select>
                      </div>
                  
                  }
                  </>) 
                }
                { actiontype === 11 && ( // import '../Form/Student' GAD : 6377b3f64e1d000329d9d9dd
                <div>
                <InputLabel htmlFor="portType">Port Type</InputLabel>
                <Select
                    native
                    label="Port Type"
                    defaultValue={props.action[1].action[0]}
                    onChange={handlePort}
                    size="small"
                    inputProps={{
                      id: 'portType',
                    }}
                    >
                    <option value={'../Port/Base'}>Base</option>
                    <option value={'../Port/Import'}>Import</option>
                  </Select>
                  <Typography variant="caption" color='gray'>Flow ID</Typography>
                  <TextareaAutosize
                    maxRows={1}
                    aria-label="maximum height"
                    defaultValue={props.action[1].action[1]}
                    onChange={handlePortData}
                    id="portdata"
                    style={{ width: 200, margin: 1 }}/>
                  </div>) 
                }
              </FormControl>
            </Box>
            </TabPanel>}
            <TabPanel value={value} index={2}>
            <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <FormControl sx={{ m: 1, minWidth: 120, margin:0}} margin="normal" size="small">
                    <Typography variant="caption" color='gray'>connect flow id</Typography>
                    <TextareaAutosize
                      maxRows={4}
                      aria-label=""
                      placeholder=""
                      defaultValue={props.action[1].action[2]}
                      onChange={handleSrc}
                      id="handleSrc"
                      style={{ width: 200, margin: 1 }}/>                 
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120, margin:0}} margin="normal" size="small">
                    <Typography variant="caption" color='gray'>menu name</Typography>
                    <TextareaAutosize
                      maxRows={4}
                      aria-label=""
                      placeholder=""
                      defaultValue={props.action[1].action[3]}
                      onChange={handleMenuName}
                      id="handleMenuName"
                      style={{ width: 200, margin: 1 }}/>                 
                </FormControl>
                <div>{ JSON.stringify(props)}</div>
              </Box>
            </TabPanel>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleClose}>Ok</Button>
          </DialogActions>
        </Dialog>}
      </div>
    );
  }