import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { cardLogic } from '../../../hoc/ApolloProvider';
import { ConstructionOutlined } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
/*
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
// <ColorButton size="small" onClick={handleClickOpen}>{props.pt===undefined?1:props.pt}</ColorButton> 
const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(grey[500]),
  backgroundColor: grey[500],
  '&:hover': {
    backgroundColor: grey[700],
  },
  minWidth: '32px',
  fontSize: 16,
  padding: '0px 2px',
  margin: '0px 0px',
}));
*/

export default function OutputEditor( props) {
    const [open, setOpen] = React.useState(false);

    const [outputs, setOutputs] = useState(props.outputs===undefined?[]:props.outputs);
    //const [output, setOutput] = useState(props.outputs===undefined?'점수더하기':props.outputs[0].output);
    const [point, setPoint] = useState<number|string>(0);
    const [weight, setWeight] = useState<number|string>(1);
    const [select,setSelect]= useState(0);
    const [viewindex,setViewIndex]= useState(0);

    useEffect( ()=> {
      for( let i = 0; i<outputs.length; i++){
        if( outputs[i].pt > 0 ){
          setSelect(i);
          setViewIndex(i);
          setPoint(outputs[i].pt);
          setWeight(outputs[i].pt);
          break;
        }
      }  
    },[outputs,open]);

    const handleOutputChange = (event: SelectChangeEvent) => {
      //setOutput(event.target.value);
      let index = outputs.findIndex( out => out.output === event.target.value );
      if( index === -1 )
        index = outputs.push({output:event.target.value, pt:0, wt:1})-1;
      setSelect(index);  

      setPoint(outputs[index].pt);
      setWeight(outputs[index].wt);
    };

    const handlePointChange = (event: SelectChangeEvent) => {
      outputs[select].pt  = Number(event.target.value) || 0;
      setPoint(event.target.value);
      /* 
      // find selected output index
      let soutput = outputs.find( out => out.output === output );
      if( soutput !== undefined ){
        soutput.pt = Number(event.target.value) || '';
        setOutputs(outputs);
        console.log(outputs);  
      }
      else{
        setOutputs([...outputs, {output:output, pt:Number(event.target.value) || '', wt: 1}]);
      }
      //setPoint(event.target.value);
      */
    };

    const handleWeightChange = (event: SelectChangeEvent) => {
      outputs[select].wt  = Number(event.target.value) || 0;
      setWeight(event.target.value);
/*      let soutput = outputs.find( out => out.output === output );
      if( soutput !== undefined ){
        soutput.wt = Number(event.target.value) || '';
        setOutputs(outputs);
        console.log(outputs);  
      }
      else{
        setOutputs([...outputs, {output:output, wt:Number(event.target.value) || '', pt: 0}]);
      }
      //setWeight(event.target.value);
*/    };

    const handleClickOpen = (event:any) => {
      setOpen(true);
      event.stopPropagation(); 
    };
  
    const handleCloseOk = (event: React.SyntheticEvent<unknown>, reason?: string) => {
      if (reason !== 'backdropClick') {
        props.onChange(outputs);
        setOpen(false);
      }
    };
    const handleCloseCancel = (event: React.SyntheticEvent<unknown>, reason?: string) => {
      if (reason !== 'backdropClick') {
        setOpen(false);
        //props.onChange(outputs);
      }
    };

    if( outputs[select] === undefined ){
      console.log('check')
    }
    
    //if( outputs[select].pt === ''){
    //  console.log('check')
    //}
    return (
      <>
        <Chip label={outputs[viewindex].pt} onClick={handleClickOpen} color="error" size="small" sx={{fontSize:'1.2rem',height:'25px'}}/>      
        {open && <Dialog disableEscapeKeyDown open={open} >
          <DialogTitle>Set the Point & Weight</DialogTitle>
          <DialogContent>
            <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel htmlFor="demo-dialog-native1">Output</InputLabel>
                <Select
                  native
                  defaultValue={outputs[select].output}
                  onChange={handleOutputChange}
                  input={<OutlinedInput label="Output" id="demo-dialog-native1" />}
                >
                  {cardLogic().outputKeys.map((outputName,index) => (
                    <option key={index} value={outputName}>{outputName}</option>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel htmlFor="demo-dialog-native2">Point</InputLabel>
                <Select
                  native                  
                  value={point}
                  onChange={handlePointChange}
                  input={<OutlinedInput label="Point" id="demo-dialog-native2" />}
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="demo-dialog-native3">Weight</InputLabel>
                <Select
                  native
                  value={weight}
                  onChange={handleWeightChange}
                  input={<OutlinedInput label="Weight" id="demo-dialog-native3" />}
                >
                  <option value={0}>0</option>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>11</option>
                </Select>
              </FormControl>
            </Box>
                {outputs.map((v,i)=>(<Box component="form" sx={{ m:0, display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                  <Typography color="text.secondary" sx={{m:1}} key={i} variant="body1">{v.output}</Typography>
                  <Typography color="text.secondary" sx={{m:1}} key={i} variant="body1">{v.pt}</Typography>
                  <Typography color="text.secondary" sx={{m:1}} key={i} variant="body1">{v.wt}</Typography>
                  </Box>
                )) }
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCancel}>Cancel</Button>
            <Button onClick={handleCloseOk}>Ok</Button>
          </DialogActions>
        </Dialog>}
      </>
    );
  }


  /**
   * 
   *
   * 
   * import { DataGrid, GridColumns, GridRowsProp } from '@mui/x-data-grid';

    const [pageSize, setPageSize] = React.useState(25);

  <Box
              sx={{
                height: 200,
                width: '100%',
                '& .MuiDataGrid-cell--editable': {
                  bgcolor: (theme) =>
                    theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
                },
              }}
            >
              <DataGrid
                autoHeight
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPage) => setPageSize(newPage)}
                pagination
                maxColumns: 3,
                sx={{
                  boxShadow: 2,
                  border: 2,
                  borderColor: 'primary.light',
                  '& .MuiDataGrid-cell:hover': {
                    color: 'primary.main',
                  },
                }}
                experimentalFeatures={{ newEditingApi: true }}
              />
            </Box>

            const columns: GridColumns = [
    { field: 'name', headerName: 'Name', width: 80, editable: true },
    { field: 'point', headerName: 'Point', width: 80, type: 'number', editable: true },
    { field: 'weight', headerName: 'Weight', width: 80, type: 'number', editable: true },
  ];
  
  const rows: GridRowsProp = [
    {
      id: 1,
      name: 'Output1',
      point: 25,
      weight: 15,
    },
    {
      id: 2,
      name: 'Output2',
      point: 35,
      weight: 15,
    },
  ];
   */
  