import { Socket } from "rete";

export const Sockets = {
  num: new Socket("Number value"),
  action: new Socket("Action"),
  data: new Socket("Data"),
  package: new Socket("Package"),
};


export var actionSocket = new Socket("Action");
export var dataSocket = new Socket("Data");
export var numSocket = new Socket("Number value");
