import Rete from "rete";
import { LinkControl } from "../Controls/LinkControl";
import { StatusControl } from "../Controls/StatusControl";
import { actionSocket } from "../Sockets/Sockets";

export class LinkCardNode extends Rete.Component {
    static type = "LinkCard";
    public task:any;

    constructor() {
        super(LinkCardNode.type);
        this.task = {
            outputs: {}
        };
    }

    builder(node) {
        //console.log("", "LinkCard", node.id, node.data);

        if(node.data.body === undefined && node.name === 'LinkCard'){
            node.data = {
                label: "Link Card",
                properties: [],
                header: [],
                status: "processing",
                body: [" Link Name",{"cat":5,"type": 0, "action":["https://liflo.io",""],"rules":[]},1,""],
                actions: []
            };
            //node.addOutput( new Rete.Output( '101', "추가하세요. +", actionSocket, false));
        }

        const status = new StatusControl(
            this.editor,
            node.data.status,
            node.id + "_STATUS",
            LinkCardNode.type
        );

        node.data.body[1].cat = 5;
        const body = new LinkControl(
            this.editor,
            node.data.body,
            node.id + "_LINK",
            LinkCardNode.type
        );

        node.addControl(status).addControl(body);
        
        if ( node.data.properties && !node.data.properties.includes("ENTRY_POINT")) {
            node.addInput(new Rete.Input("act", "Link", actionSocket, true));
        }

        if (node.data.actions) {
            node.data.actions.forEach(action => {
                node.addOutput( new Rete.Output(action.id, action.text, actionSocket, false));
            });
        }
        
        return node;
    }

    worker(node, inputs, outputs) {
        //console.log("LinkCard", "worker", node.id, node.data);
        if (!this.editor)
            return
        const nodeRef = this.editor.nodes.find(it => it.id === node.id)
        if (!nodeRef)
            return
    }
}
