import React, { useState, useRef, useCallback, useEffect} from 'react';


import ReactAvatarEditor, { Position }  from 'react-avatar-editor'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Icon } from "./Icon";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import { blue, yellow } from '@mui/material/colors';
import Stack from '@mui/material/Stack';
import { MuiColorInput, MuiColorInputColors } from 'mui-color-input';
import RotateLeft from '@mui/icons-material/RotateLeft';
import RotateRight from '@mui/icons-material/RotateRight';
import Slider from '@mui/material/Slider';
import Card from '@mui/material/Card';

import { useDropzone }  from 'react-dropzone'

import styled from "styled-components";
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import ColorizeIcon from '@mui/icons-material/Colorize';

import axios from 'axios';
const MuiColorInputStyled = styled(MuiColorInput)`
  display: inline-flex;
  align-items: center;
  width:250px;
`

export default function BgEditor( props) {

  const avatarEditorRef = useRef<ReactAvatarEditor>(null);

  const [image, setImage] = useState(""); // current image
  const [avatar, setAvatar] = useState<{  // working image
    file?: unknown;
    preview: string;
  } | null>(null);

  const [position, setPosition] = useState<Position>();
  const [scale, setScale] =  useState(1);  
  const [rotate, setRotate] = useState(0);
  const [color, setColor] = useState('#FFFFFFFF');
  const [openDlg, setOpen] = useState(false);
  const [pickColor, setPickColor] = useState(false);

  useEffect(()=>{
    if( openDlg === false) return;
    console.log('props.data.imgSrc:'+props.data.imgSrc);
    if( props.node.data.body.length >= 4){
      let imgName = `${props.node.data.body[3].replace(/fileServer\/images\//i, "")}`;
      if( props.node.data.body.length === 5){
        setPosition(props.node.data.body[4].posiotion);
        setScale(props.node.data.body[4].scale);
        setRotate(props.node.data.body[4].rotate);
        setColor(props.node.data.body[4].bgcolor);  
        imgName = `${props.node.data.body[4].src.replace(/fileServer\/images\//i, "")}`;
      }
      
      if( imgName === "") imgName = "file-1663555342553.png";
      setImage( imgName);
      const loadedImage = {
        preview: "https://liflo.io/images/"+(imgName),
        crossOrigin: "anonymous"
      };
      /*
      if( imgName === "") imgName = "tr.png";
      setImage( imgName);
      const loadedImage = {
        preview: "/"+(imgName),
        crossOrigin: "anonymous"
      };
      */
      console.log("db -> web:"+imgName)
      setAvatar(loadedImage);
    }
  },[openDlg]); // props.node.data.body, image


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
    console.log( 'Dlg-handleClose:'+reason);
    if (reason !== 'backdropClick') {
    }
  };
  
  const handleOk = (event: React.SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== 'backdropClick') {
      uploadPhoto();
      props.onChange(props.node);
    }
  };

  const handleCancel = (event: React.SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== 'backdropClick') {
      const imagea = {
        preview:"https://liflo.io/images/"+image,
        crossOrigin: "anonymous"
      };  
      setAvatar(imagea);  

      setOpen(false);
      props.onChange(props.node);
    }
  };

  const ratio = 1.56;
  const width = 400;
  const height = width*ratio|0;

  const handleAvatarSubmit = () => {
    const canvas = avatarEditorRef.current!.getImage();
    const img = canvas.toDataURL('image/jpeg', 0.9);
    props.onConfirm?.(img);
  };    

  const handleColorChange = (newValue: string, colors: MuiColorInputColors) => {
    console.log("change", colors.hex);
    setColor(colors.hex);
    // action('changed')(newValue);
  };

  const handleScale = e => {

    const scale = e.target.value;
    setScale(scale);
  
  }
  
  
  const rotateLeft = e => {
    
    e.preventDefault();
    //  alert('size(wxh):'+this.state.width+'x'+this.state.height);
    setRotate(rotate - 90);
  }

  const rotateRight = e => {
    e.preventDefault()
    //  alert('size(wxh):'+this.state.width+'x'+this.state.height);
    setRotate(rotate + 90);
  }

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    //alert('onDrop');
    if (acceptedFiles.length === 0) return;
    const file = acceptedFiles[0];
    const image = {
      file,
      preview: URL.createObjectURL(file),
      crossOrigin: "anonymous"
    };
    setAvatar(image);
  }, []);

  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop });

  const uploadPhoto = async () => {
    if (avatarEditorRef.current == null) return;
    //setUpload(false);
  
    
    const canvas = avatarEditorRef.current.getImageScaledToCanvas(); //.getImage(); // This is a HTMLCanvasElement.
    // It can be made into a data URL or a blob, drawn on another canvas, or added to the DOM.
    
    // If you want the image resized to the canvas size (also a HTMLCanvasElement)
    //const canvas = editorRef.current.getImageScaledToCanvas();    
    //var canvasScaled = editorRef.current.getImageScaledToCanvas();
    canvas.crossOrigin = 'anonymous';
    
    fileUpload(canvas);
  };
  
  const fileSrcUpload = (canvas) => {
    const data = new FormData()
    props.data.imgSrc = avatarEditorRef.current.props.image;
    fetch(avatarEditorRef.current.props.image)
    .then(function(response) {
      return response.blob()
    })
    .then(function(blob) {
      data.append('file', blob, blob.type === 'image/svg+xml' ? 'check.svg':'check.png')
      axios
        .post(`https://liflo.io/upload`, data, {
          // .post(`https://localhost:3006/upload`, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          let upimage = res.data.filename;
          let bgimage = "fileServer/images/"+upimage;
          /*
          if(props.node.data.body.length === 3)
            props.node.data.body.push(bgimage);
          else //if(props.node.data.body.length === 4)
            props.node.data.body[3] = bgimage;  
          */
          props.node.data.body[4] = {src:bgimage, bgcolor:color, position:position, scale:scale, rotate:rotate}  
          //console.log(`fileSrcUpload:${bgimage}`)     
          setOpen(false);       
      });
    })
  }

  const fileUpload = (canvas) => {
    const data = new FormData()
    canvas.toBlob((blob) => {
      data.append('file', blob, blob.type === 'image/svg+xml' ? 'check.svg':'check.png')
  
      axios
        .post(`https://liflo.io/upload`, data, {
          // .post(`https://localhost:3006/upload`, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          let upimage = res.data.filename;
          let bgimage = "fileServer/images/"+upimage;
          if(props.node.data.body.length === 3)
            props.node.data.body.push(bgimage);
          else //if(props.node.data.body.length === 4)
            props.node.data.body[3] = bgimage;  
          
          //console.log(`fileUpload:${bgimage}`)            
          fileSrcUpload(canvas);
            //props.node.data.body[4] = {src:bgimage, bgcolor:color, position:position, scale:scale, rotate:rotate}  
          /*
          const imageAvatar = {
              preview:"https://liflo.io/images/"+upimage,
              crossOrigin: "anonymous"
          };  

          console.log(`fileUpload:${upimage}`)            
          setImage(upimage)
          setAvatar(imageAvatar);  
          */
          //setUpload(true);
          //setUploadStatus("completed:"+res.data.filename);    
        })
    })
  }
  
  let styles = {};
  if (isDragActive) {
    styles = {
      borderStyle: 'solid',
      borderColor: '#6c6',
      backgroundColor: '#eee'
    };
  } else if (isDragReject) {
    styles = {
      borderStyle: 'solid',
      borderColor: '#c66',
      backgroundColor: '#eee'
    };
  }

  const handlePositionChange = (position: any) => {
    setPosition(position);  
    //console.log(position)
  };

  function componentToHex(component: number): string {
    const hex = component.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }
  
  function rgbToHex(r: number, g: number, b: number): `#${string}` {
    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
  }

  const handleMouseDown = (event: any) => {
    if( pickColor){
      let ctx = event.target.getContext("2d");
      const [red, green, blue]  = ctx.getImageData(event.nativeEvent.offsetX, event.nativeEvent.offsetY, 1, 1).data;
      setColor(rgbToHex(red, green, blue))
      //console.log(event.nativeEvent.offsetX + ',' +event.nativeEvent.offsetX +':'+color)
      ctx.lineWidth = 2;
      // Wall
      ctx.strokeRect(event.nativeEvent.offsetX, event.nativeEvent.offsetY, 10, 10);  
    }
    else
      avatarEditorRef.current.handleMouseDown(event);
  };

  /*
  if( avatar !== null )
    console.log('before lender(avatar):'+avatar.preview);
  else
    console.log('before lender:'+'no set');
  */
  // body: ["", {"type": 1, "action":["https://cam.liflo.io/c/:cid"],"rules":""}, 2],
  return (
    <>
      <Icon onClick={handleClickOpen} id={'9'}/>
      { openDlg && <Dialog disableEscapeKeyDown open={openDlg} keepMounted={true} onClose={handleClose}>
        <DialogTitle>Background Editor</DialogTitle>
        <DialogContent >
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap' }}>
            { avatar !== null &&
            <Card sx={{ borderRadius: 3,
              boxShadow: 'none',
              position: 'relative',
              '&:after': {
                content: '""',
                display: 'block',
                position: 'absolute',
                bottom: 0,
                border:0,
                padding:0,
                zIndex: -9,
                //background: 'linear-gradient(180deg, rgba(0,0,0,0), grey)',
              }, }} variant="outlined"
              style={{backgroundColor: color, width:width, height:height}}>
              <ReactAvatarEditor
                ref={avatarEditorRef}
                width={width}
                height={height}
                border={0}
                image={avatar.preview}
                onPositionChange={handlePositionChange}
                onMouseDown ={ handleMouseDown}
                crossOrigin={'anonymous'}
                position={position}
                scale={scale}
                rotate={rotate}
                style={{}}                
              />
            </Card>}
          </Box>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap', width:(width-50), paddingTop:1 }}>
            <Stack direction="row" justifyContent="center" spacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{paddingBottom:1 }}>
              <MuiColorInputStyled  size={'small'} value={color} onChange={handleColorChange} format={'hex'} variant="outlined"  />
              <Avatar sx={{ bgcolor: pickColor?yellow[900]:yellow[500], paddingLeft:'0px' } } onClick={()=>setPickColor(!pickColor)} >
              <ColorizeIcon/>
              </Avatar>
            </Stack>
            <Stack direction="row" justifyContent="center" spacing={{ xs: 1, sm: 1, md: 1 }}>
            <div
            style={styles} 
            {...getRootProps()}>
              <input {...getInputProps()} />
                  <Avatar sx={{ bgcolor: blue[500] }}>
                      <PhotoCamera />
                  </Avatar>
            </div>
              <Avatar sx={{ bgcolor: blue[500], paddingLeft:'0px' } } onClick={rotateLeft} >
                  <RotateLeft />
              </Avatar>
              <Avatar sx={{ bgcolor: blue[500], paddingLeft:'1px' }} onClick={rotateRight} >
                  <RotateRight  sx={{ fontSize: 20 }}/>
              </Avatar>
            <span style={{ marginLeft: '1px' }}>
              <ImageIcon fontSize="small" />
            </span>     
            <Slider name="scale" aria-labelledby="label" onChange={handleScale} color="secondary"
                min={0.1} max={5} step={0.01} defaultValue={1} sx={{width:80}}/>          
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleOk}>Ok</Button>
        </DialogActions>
      </Dialog>}
    </>
  );
}


  /*
//---------------------------------------------------------------
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { red, pink, green, blue, grey } from '@mui/material/colors';
import createApolloClient from '../../../hoc/ApolloProvider';

import { useQuery, useMutation, gql,} from "@apollo/client";
// http://localhost:3000/card/62beb9beb4a19a4c82d096ae
const getCardGQL = gql`
query getCard($id: ID!){
    getCard( id:$id){
    id
    card{
        id
        title
        category
        data
        img
      }
    success
    error
    }
}
`;

if( props.node.data.body.length !== 4)
  const { loading, error, data, refetch, networkStatus } = useQuery(
    getCardGQL,
    {
      client: createApolloClient,
      variables: { "id": props.node.id},
      //notifyOnNetworkStatusChange: true
      // pollInterval: 500
      onCompleted: (data) => {
        //`${card.img.replace(/fileServer/i, "https://liflo.io")}`
        let imgName = `${data.getCard.card.img.replace(/fileServer\/images\//i, "")}`;
        setImage(imgName);

        const loadedImage = {
          preview: "https://liflo.io/images/"+(imgName === "" ? "file-1658751593961.png":imgName),
          crossOrigin: "Anonymous"
        };
        console.log("db -> web:"+imgName)
        setAvatar(loadedImage);
      },
    }
  );  
  */
