// https://mui.com/material-ui/react-card/

import React, { useState, useRef, useCallback, FC, useEffect} from 'react';

import {
  useQuery,
  useMutation,
  gql,
} from "@apollo/client";

import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red, pink, green, blue, grey } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Slider from '@mui/material/Slider';
import RotateLeft from '@mui/icons-material/RotateLeft';
import RotateRight from '@mui/icons-material/RotateRight';
import CancelIcon from '@mui/icons-material/Cancel';
import ImageIcon from '@mui/icons-material/Image';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import { makeStyles } from '@mui/styles';

import axios from 'axios'
import ReactAvatarEditor, { Position }  from 'react-avatar-editor'
import InputRange, { Range } from 'react-input-range';

import { useDropzone }  from 'react-dropzone'
import EXIF from 'exif-js';
import { array } from 'yup';
import { MenuItem } from '@mui/material';
import { DeviceUnknown } from '@mui/icons-material';

const avatarModalStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center'
  },
  dropZone: {
    width: 250,
    height: 250,
    borderWidth: 2,
    borderColor: '#757e84',
    borderStyle: 'dashed',
    borderRadius: '15%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  avatarEditor: {
    display: 'flex',
    flexDirection: 'column'
  },
  avatarEditorBg: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    backgroundImage: `linear-gradient(45deg, #a3a9ad 25%, transparent 25%),
    linear-gradient(-45deg, #a3a9ad 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #a3a9ad 75%),
    linear-gradient(-45deg, transparent 75%, #a3a9ad 75%)`,
    backgroundSize: '20px 20px',
    backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px'
  },
  avatarEditorClose: {
    position: 'absolute',
    right: '5px',
    color: 'white'
  },
  avatarSliderShell: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    marginTop: '5px'
  }
}));

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

//---------------------------------------------------------------
// http://localhost:3000/card/62beb9beb4a19a4c82d096ae
const getCardGQL = gql`
query getCard($id: ID!){
    getCard( id:$id){
    id
    card{
        id
        title
        category
        data
      }
    success
    error
    }
}
`;

function CaseList( {onInit, onImageSelect, cardid}){
  const { loading, error, data, refetch, networkStatus } = useQuery(
    getCardGQL,
    {
      variables: { "id": cardid},
      //notifyOnNetworkStatusChange: true
      // pollInterval: 500
      onCompleted: (data) => {
        if( data !== undefined)
        onInit(JSON.parse(data.getCard.card.data));
        },
    }
  );  

  //useEffect(() => {
  //}, []);


  if (networkStatus === 4) return <p>Refetching!</p>;
  if (loading ) return <p>loading</p>;
  if (error) return <p>Error!: {error}</p>;
  const cases = JSON.parse(data.getCard.card.data);
  function onChange(event){
    onImageSelect(event.target.value, cases); // .cases[event.target.value].id, cases.cases[event.target.value].img
  }  

  console.log("-------- select ---------------"+cases.cases);
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
    <Select 
    name="cases" onChange={onChange} defaultValue="0">
    {cases.cases.map((v,i) => 
      <MenuItem key={i} id={v.id} value={i} >
      {v.title}
      </MenuItem>
    )}
    </Select>
    </FormControl>
 );  
}
/**
 * 

    <div style={{ zIndex: 200}}>
    <select name="cases">
      { cases.cases.map((v,i) => (
        v.title !== undefined && (<option key={i} id={v.id} value={v.img}>
        {v.title}
        </option>)
      ))}
    </select>
    </div>
    
    
    {cases.cases.every((v,i) => (
        <option key={i} id={i} value={v.img}>
        {v.title}
        </option>
      ))}

      <Select name="cases" onChange={onChange} defaultValue="">
        {cases.cases.every((v,i) => 
          <MenuItem key={i} id={i} value={""}>
          {i}
          </MenuItem>
        )}
      </Select>
  * 
 * 
 *         {data.getCard.card.data.map(case => (
          <option id={case.title} value={case.img}>
            {case.title}
          </option>
        ))}

 */
//------------------------------------------------------------------

export const updateCardMGQL = gql`
mutation updateCardM( $id: ID! $input:CardUpdate!){
  updateCardM( id:$id, input: $input) {
    success
    error
    id
  }
}
`;

function CaseUpdate( {cardid, casedata}) {

  const [updateCardM, { loading, error, data }] = useMutation(updateCardMGQL, {
    refetchQueries: [getCardGQL, "getCard"]
  });


  useEffect(()=>{
    if( data !== undefined ){
      //let jdata = JSON.parse(data.updateCardM);
      //alert(jdata);
    }
  },[data]);

  if (loading) return <p>image update ...</p>;
  if (error) return <p>Error!: {error.message}</p>;
  console.log(data);

  function updateCard(){
    const cases = JSON.stringify(casedata);
    //alert(cardid+"/"+cases);
    updateCardM({ variables: {   
      "id": cardid, 
      "input": {
        //"title": "",
        //"category": "switch",
        "data": cases
      }
    }
    });
  }
/*
  function openFlow() {
    const url = 'https://liflo.io/link/'+cardLink().flowid+'/'+cardLink().cardid;
    window.open(url, '_blank', "height=800,width=550");
  }
*/

  return(
    <Avatar sx={{ bgcolor: grey[500] }} onClick={updateCard} >
    <ArrowUpwardIcon />
    </Avatar>
  );
}


export const ImageCard: FC<Props> = ({cardid, title}) => {
  const [expanded, setExpanded] = useState(false);
  const [allowZoomOut, setAllowZoomOut] = useState(1);
  const [image, setImage] = useState("");
  const [upload, setUpload] = useState(false);
  const [selImg, setSelImg] = useState<{
    index: number;
    id: string;
  } | null>({index:0, id:""});
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] =  useState(1);  
  const [position, setPosition] = useState<Position>();
  const [avatar, setAvatar] = useState<{
    file?: unknown;
    preview: string;
  } | null>(null);
  const [caseData, setCaseData] = useState(null);
  const loading = false;
  
  useEffect(() => {
  }, [caseData]);

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    if (acceptedFiles.length === 0) return;
    const file = acceptedFiles[0];
    const image = {
      file,
      preview: URL.createObjectURL(file)
    };
    setAvatar(image);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({ onDrop });

  const classes: Record<
    | 'root'
    | 'dropZone'
    | 'avatarEditor'
    | 'avatarEditorBg'
    | 'avatarEditorClose'
    | 'avatarSliderShell',
    string
  > = avatarModalStyles();

  let styles = {};
  if (isDragActive) {
    styles = {
      borderStyle: 'solid',
      borderColor: '#6c6',
      backgroundColor: '#eee'
    };
  } else if (isDragReject) {
    styles = {
      borderStyle: 'solid',
      borderColor: '#c66',
      backgroundColor: '#eee'
    };
  }

  const onImageInit = (data) => {
    setCaseData( data);
    //for( let i = 0 ; i < data.cases.length ; i++){
    //  itemData[i].img = data.cases[i].img === "" ? "file-1658751593961.png":data.cases[i].img;
    //}
    const image = {
      preview: "https://liflo.io/images/"+(data.cases[0].img === "" ? "file-1658751593961.png":data.cases[0].img)
    };
    setAvatar(image);
  }

  const onImageSelect = (index) => {
    //jsondata = target[target.selectedIndex].value; 
    //this.jsondata = jsondata;
    //this.cardid = id; .cases[event.target.value].id, cases.cases[event.target.value].img
    setSelImg({index:index,id:caseData.cases[index].id});
    let imgNew = caseData.cases[index].img === "" ? "file-1658751593961.png":caseData.cases[index].img;
    
    if( imgNew === "" ) return;
    const image = {
      preview: "https://liflo.io/images/"+imgNew
    };
    setAvatar(image);
  }

  const  editorRef = useRef<ReactAvatarEditor>(null);

  const closeWindow = e => {
    e.preventDefault()
    //  alert('size(wxh):'+this.state.width+'x'+this.state.height);
    /*
    try {
      window.opener.onClose("hello this!");
    }
    catch (err) {
      alert(err);
    }
    */
    window.close();
    return false;
  }

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleNewImage = (e) => {
    
    //var that = this;
    setImage(e.target.files[0]);

    EXIF.getData(e.target.files[0], function () {
        var orientation = EXIF.getTag(this, "Orientation");
        let rotatePic = 0;
        let nWidth = width;
        let nHeight = height;

        switch (orientation) {
        case 8:
            rotatePic = 270;
            nWidth = height;
            nHeight = width;
            break;
        case 6:
            rotatePic = 90;
            nWidth = height;
            nHeight = width;
            break;
        case 3:
            rotatePic = 180;
            break;
        default:
            rotatePic = 0;

        }

        setRotate(rotatePic);
        setWidth(nWidth);
        setHeight(nHeight);
    });
    
}

const uploadPhoto = async () => {
  if (editorRef.current == null) return;
  setUpload(false);

  //const canvas = editorRef.current.getImage(); // This is a HTMLCanvasElement.
  const canvas = editorRef.current.getImageScaledToCanvas();
  fileUpload(canvas);
  //const dataURL = canvas.toDataURL(mime);
  //setImageDataUrl(dataURL);
  /*
  mutate({
    variables: {
      filename: 'profile-pic',
      filetype: mime
    }
  });
  */
};

const fileUpload = (canvas) => {
  const data = new FormData()
  //const dataOut = {}
  //const self = this
  //dataOut.name = 'image'
  canvas.toBlob((blob) => {
    data.append('file', blob, 'check.png')

    axios
      .post(`https://liflo.io/upload`, data, {
        // .post(`https://localhost:3006/upload`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then((res) => {
        console.log(`fileUpload:${res.data.filename}`)
        
        //itemData[selImg.index].img = res.data.filename;
        caseData.cases[selImg.index].img = res.data.filename;
        setCaseData( caseData);

        setUpload(true);


        // https://liflo.io/images/fname
        //dataOut.value = res.data.filename
        //console.log(`fileUpload:dataOut.value${dataOut.value}`)
        //console.log(`this:${self}`)
        //self.changeUpdate(dataOut);
      })
  })
}

const handleScale = e => {
  
    const scale = e.target.value;
    console.log('scale:'+scale);
    setScale(scale);
  
}


  const rotateLeft = e => {
    
    e.preventDefault()
    //  alert('size(wxh):'+this.state.width+'x'+this.state.height);
    setRotate(rotate - 90);
  }

  const rotateRight = e => {
    e.preventDefault()
    //  alert('size(wxh):'+this.state.width+'x'+this.state.height);
    setRotate(rotate + 90);
  }

  const handleDrop = acceptedFiles => {
    setImage(acceptedFiles[0]);
  }

  const Input = styled("input")({
    display: "none",
  });
  
  return (
    <Card sx={{ maxWidth: "300", Height: "550", borderRadius: 3 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            C
          </Avatar>
        }
        action={
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={{ xs: 1, sm: 1, md: 1 }}>
          <CaseList  onInit={(data) => onImageInit(data)} onImageSelect={(index, data) => onImageSelect(index)} cardid={cardid} />

          <CaseUpdate cardid={cardid} casedata={caseData}/>
          <Avatar sx={{ bgcolor: grey[500] }} onClick={closeWindow} >
                  <ClearIcon />
          </Avatar>
          </Stack>
        }
        title={""}
        subheader=""
      >
      </CardHeader>
 
      <CardContent sx={{ pt:0}}>

        {avatar === null ? (
          <div/>
        ) : (
          <div className={classes.avatarEditor}>
            <div className={classes.avatarEditorBg}>
              <ReactAvatarEditor
                width={256}
                height={256}
                ref={editorRef}
                image={avatar.preview}
                position={position}
                scale={scale}
                rotate={rotate}
                onPositionChange={position => setPosition(position)}
              />
              <span className={classes.avatarEditorClose}>
                {loading ? (
                  <div>Loading...</div>
                ) : (
                  <Avatar sx={{ bgcolor: grey[500] }} onClick={uploadPhoto} >
                  <DoneIcon />
                  </Avatar>                )}
              </span>
            </div>
          </div>)
        }
            <div className={classes.avatarSliderShell}>
            <Stack direction="row" justifyContent="center" spacing={{ xs: 2, sm: 1, md: 1 }}>
              <div
              style={styles}
              {...getRootProps()}>
              <input {...getInputProps()} />
              <div style={{ textAlign: 'center' }}>
                  <Avatar sx={{ bgcolor: blue[500] }}>
                      <PhotoCamera />
                  </Avatar>
              </div>
              {isDragReject && <div>Unsupported file type...</div>}
            </div>
              <Avatar sx={{ bgcolor: blue[500] }} onClick={rotateLeft} >
                  <RotateLeft />
              </Avatar>
              <Avatar sx={{ bgcolor: blue[500] }} onClick={rotateRight} >
                  <RotateRight />
              </Avatar>
            </Stack>
            <span style={{ marginLeft: '10px' }}>
              <ImageIcon fontSize="small" />
            </span>     
            <Slider name="scale" aria-labelledby="label" onChange={handleScale} color="secondary"
          min={0.1} max={5} step={0.01} defaultValue={1}/>
            <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
          </div>
          {caseData !== null &&(
          <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
          <ImageList cols={2} >
            {caseData.cases.map((item) => 
                <ImageListItem key={item.title}>
              { item.img !== "" && (
                  <img
                  src={`${"https://liflo.io/images/"+item.img}?w=150&h=150&fit=crop&auto=format`}
                  srcSet={`${"https://liflo.io/images/"+item.img}?w=150&h=150&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  loading="lazy"/>
                  )}
                  </ImageListItem>
            )}
          
          </ImageList>
          </CardContent>
          </Collapse>  
          )}
      </CardContent>
    </Card>
  );
}
/*

<div className={classes.root}>
            <div
              className={classes.dropZone}
              style={styles}
              {...getRootProps()}>
              <input {...getInputProps()} />
              <div style={{ textAlign: 'center' }}>
                {' '}
                {isDragAccept ? 'Drop' : 'Drag'} your photo here or click to
                browse image for upload...
              </div>
              {isDragReject && <div>Unsupported file type...</div>}
            </div>
          </div>

          {itemData.map((item) => 
              <ImageListItem key={item.title}>
            { item.img !== "" && (
                <img
                src={`${"https://liflo.io/images/"+item.img}?w=150&h=150&fit=crop&auto=format`}
                srcSet={`${"https://liflo.io/images/"+item.img}?w=150&h=150&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"/>
                )}
                </ImageListItem>
          )}

const itemData = [
  {
    img: '',
    title: '1',
  },
  {
    img: '',
    title: '2',
  },
  {
    img: '',
    title: '3',
  },
  {
    img: '',
    title: '4',
  },
];
*/

interface Props {
  title?: string;
  cardid?: string;
}