import Rete from "rete";
import { BodyControl } from "../Controls/BodyControl";
import { StatusControl } from "../Controls/StatusControl";
import { actionSocket } from "../Sockets/Sockets";

export class ActionCardNode extends Rete.Component {
    static type = "ActionCard";
    public task:any;

    constructor() {
        super(ActionCardNode.type);
        this.task = {
            outputs: {}
        };
    }

    builder(node) {
       // console.log("Create Card", "ActionCard", node.id, node.data);

        if(node.data.body === undefined && node.name === 'ActionCard'){
            node.data = {
                label: "Action Card",
                properties: [],
                header: [],
                status: "processing",
                body: ["", {"cat":4,"type": 2, "action":["https://cam.liflo.io/c/:cid",""],"rules":[]},1,""],
                actions: [
                    {
                        id: "Connect_1",
                        type: "CONNECT_ACTION",
                        text: "다음",
                        img: "",
                        outputs:[],
                        pt:0.0,
                        wt:1.0
                    }
                ]
            };
            //node.addOutput( new Rete.Output( '101', "추가하세요. +", actionSocket, false));
        }

        node.data.body[1].cat = 4;
        const body = new BodyControl(
            this.editor,
            node.data.body,
            node.id + "_BODY",
            ActionCardNode.type
        );

        const status = new StatusControl(
            this.editor,
            node.data.status,
            node.id + "_STATUS",
            ActionCardNode.type
        );

        node.addControl(status).addControl(body);
        

        if ( node.data.properties && !node.data.properties.includes("ENTRY_POINT")) {
            node.addInput(new Rete.Input("act", "Link", actionSocket, true));
        }

        if (node.data.actions) {
            node.data.actions.forEach(action => {
                node.addOutput( new Rete.Output(action.id, action.text, actionSocket, false));
            });
        }
        
        return node;
    }

    worker(node, inputs, outputs) {
        //console.log("ActionCard", "worker", node.id, node.data);
        if (!this.editor)
            return
        const nodeRef = this.editor.nodes.find(it => it.id === node.id)
        if (!nodeRef)
            return
    }
}
