import React from "react";
import styled from "styled-components";
import { Control } from "rete";
import { ReactControl } from "./ReactControl";
import { Icon } from "../Views/Icon";
import { LinkEditControl } from "../Views/Link";

const Styled = {
    StatusContainer: styled.div`
        height: 72px;
    `,
    EditField: styled.div`
    position: relative;
    display: inline-flex;
    width: 100%;
    margin-bottom: 8px;
    padding: 5px;
    background: linear-gradient(
        119.16deg,
        rgb(118, 129, 0) 0%,
        rgb(132, 132, 0) 100%
    );
    `,
    PrimaryAction: styled.button`
    align-self: flex-end;
    background: linear-gradient(
        119.16deg,
        rgb(0, 118, 129) 0%,
        rgb(0, 132, 132) 100%
    );
    border: none;
    border-radius: 26px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 8px,
        rgba(0, 0, 0, 0.06) 0px 1px 4px;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    font-weight: lighter;
    color: white;
    cursor: pointer;
    display: flex;
    height: 46px;
    width: 80%;
    margin: 0;
    margin-top: 4px;
    margin-right: 0px;
    margin-bottom: 0;
    min-width: 200px;
    overflow: visible;
    padding: 9px 10px 12px 10px;
    -webkit-appearance: button;
    -webkit-box-pack: justify;
`
};


export class InfoControl extends Control  {

    public render:any;
    public component:any;
    public props: any;
	public update: any;   
    
    constructor(emitter, data, id, elementType) {
        super(id);
        const args = arguments;
        this.render = "react";
        this.component = ReactControl;
        this.props = {
            emitter,
            id,
            data,
            name: elementType,
            onChange: event => {
                this.props.data[event.target.id] = event.target.value;
                this.putData("body", this.props.data);
                this.update();
            },
            putData: () => this.putData.apply(this, args as any),
            render: () => this.renderControl()
        };
    }
    async onCreateNode( name: string){
        for(const component of this.props.emitter.components.values()){
            if( component.name === name){
                let node = await component.createNode();
                this.props.emitter.addNode(node);
                break;    
            }
        }
    }

    onLinkURL(url:string){
        //const curl = "https://liflo.io/link/"+url+"/0";
        let curl = ((window.location.host.indexOf('localhost') !== -1) ? "http://localhost:3006":"https://fg.liflo.io")+"/gen/"+url;
        //const curl = 'http://192.168.0.65:3007/flow/'+url;
        //debug 1 const curl = 'http://localhost:3006/flow/'+url;
        window.open(curl, '_blank', "height=800,width=550");
    }

    renderControl() {
        return (
            <>
            <Styled.StatusContainer>
                <Styled.PrimaryAction onClick={() => this.onLinkURL(this.props.data[0])}>
                <Icon id={'8'} />_ flow execute
                </Styled.PrimaryAction> 
            </Styled.StatusContainer>
            <br/>
            <Styled.StatusContainer>    
                <Styled.PrimaryAction onClick={() => this.onCreateNode("StartCard")}>
                <Icon id={'1'} />_ add Start Card
                </Styled.PrimaryAction> 
            </Styled.StatusContainer>
            <Styled.StatusContainer>    
                <Styled.PrimaryAction onClick={() => this.onCreateNode("SwitchCard")}>
                <Icon id={'1'} />_ add Select Card
                </Styled.PrimaryAction> 
            </Styled.StatusContainer>
            <Styled.StatusContainer>    
                <Styled.PrimaryAction onClick={() => this.onCreateNode("ActionCard")}>
                <Icon id={'1'} />_ add Action Card
                </Styled.PrimaryAction> 
            </Styled.StatusContainer>
            <Styled.StatusContainer>    
                <Styled.PrimaryAction onClick={() => this.onCreateNode("LinkCard")}>
                <Icon id={'1'} />_ add Link Card
                </Styled.PrimaryAction> 
            </Styled.StatusContainer>
            <Styled.StatusContainer>    
                <Styled.PrimaryAction onClick={() => this.onCreateNode("EndCard")}>
                <Icon id={'1'} />_ add End Card
                </Styled.PrimaryAction> 
            </Styled.StatusContainer>
            </>
            )
    }
}

/**
 *             <Styled.StatusContainer>    
                <Styled.PrimaryAction onClick={() => this.onCreateNode("PoseCard")}>
                <Icon id={'1'} />_ add Pose Card
                </Styled.PrimaryAction> 
            </Styled.StatusContainer>

 */