import { ApolloClient, createHttpLink, from, InMemoryCache, makeVar } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
//import { setContext } from '@apollo/client/link/context';
//import jwtManager from '../services/jwtManager';
//import { refreshAccessToken } from './AuthProvider';

/*
const authLink = setContext((_, { headers }) => {
    const { getToken } = jwtManager();
    const access_token = getToken();
    return {
        headers: {
            ...headers,
            authorization: access_token ? `Bearer ${access_token}` : ""
        }
    };
});
*/

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
            switch (extensions && extensions.code) {
                case 'UNAUTHENTICATED':
                    const oldHeaders = operation.getContext().headers;
                    operation.setContext({
                    headers: {
                        ...oldHeaders,
//                        authorization: refreshAccessToken(),
                    },
                    });
                    return forward(operation);
                default:
                    console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            }
        })
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);
});
  
//------------------------------------------------------
interface cardLinkType {
    version: string;
    dev: boolean;
    flowid: string; // callee flow id 
    cardid: string; // callee start card id
    flowcardid: string; // callee - flow card id
}

export const cardLink = makeVar<cardLinkType>({ version: "15.9", dev: true, flowid: "", cardid: "", flowcardid: "" });

//-------------------------------------------------------
/*
interface outputType {
    output: string;
    pt: number;
    wt: number;
}
*/
interface cardLogicType {
    outputKeys: Array<string>;
}

export const cardLogic = makeVar<cardLogicType>({outputKeys:['plus']});

//-------------------------------------------------------

const httpLink = createHttpLink({
    uri: "https://liflo.io/graphql",
//    credentials: 'include'
});
  
const createApolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([errorLink, 
//        authLink, 
        httpLink]),

});

export default createApolloClient;