import React from "react";
import styled from "styled-components";
import { Control } from "rete";
import { ReactControl } from "./ReactControl";
import { Icon } from "../Views/Icon";
import { LinkEditControl } from "../Views/Link";

const Styled = {
    StatusContainer: styled.div`
        height: 72px;
    `,
    EditField: styled.div`
    position: relative;
    display: inline-flex;
    width: 100%;
    margin-bottom: 8px;
    padding: 5px;
    background: linear-gradient(
        119.16deg,
        rgb(118, 129, 0) 0%,
        rgb(132, 132, 0) 100%
    );
    `,
    PrimaryAction: styled.button`
    align-self: flex-end;
    background: linear-gradient(
        119.16deg,
        rgb(0, 118, 129) 0%,
        rgb(0, 132, 132) 100%
    );
    border: none;
    border-radius: 26px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 8px,
        rgba(0, 0, 0, 0.06) 0px 1px 4px;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    font-weight: lighter;
    color: white;
    cursor: pointer;
    display: flex;
    height: 46px;
    width: 80%;
    margin: 0;
    margin-top: 4px;
    margin-right: 0px;
    margin-bottom: 0;
    min-width: 200px;
    overflow: visible;
    padding: 9px 10px 12px 10px;
    -webkit-appearance: button;
    -webkit-box-pack: justify;
`
};


export class LinkControl extends Control  {

    public render:any;
    public component:any;
    public props: any;
	public update: any;   
    
    constructor(emitter, data, id, elementType) {
        super(id);
        const args = arguments;
        this.render = "react";
        this.component = ReactControl;
        this.props = {
            emitter,
            id,
            data,
            name: elementType,
            onChange: event => {
                this.props.data[event.target.id] = event.target.value;
                this.putData("body", this.props.data);
                this.update();
            },
            putData: () => this.putData.apply(this, args as any),
            render: () => this.renderControl()
        };
    }

    onLinkURL(url:string){
        //const url = this.props.data[1];
        window.open(url, '_blank', "height=800,width=550");
    }

    renderControl() {
        return (
            <Styled.StatusContainer>
                <Styled.PrimaryAction onClick={() => this.onLinkURL(this.props.data[1])}>
                <Icon id={'8'} />  {this.props.data[0]}
                </Styled.PrimaryAction> <br/><br/>
                <br/> 
                <Styled.EditField>
                    <Icon id={'5'} /> <LinkEditControl id={'0'} vkey={"0"} value={this.props.data[0]} width={100} edit={true} 
                    onChange={e => this.props.onChange(e)}/>
                </Styled.EditField>
                <br/>
                <Styled.EditField>
                    <Icon id={'5'} /> <LinkEditControl id={'1'} vkey={"1"} value={this.props.data[1]} width={100} edit={true} onChange={e => this.props.onChange(e)}/>
                </Styled.EditField>

            </Styled.StatusContainer>)
    }
}
