
import React, { Component, useEffect} from 'react'

import { Parallax, ParallaxLayer } from '@react-spring/parallax'

import {init} from "../containers/FlowEditor/editor";

    
import {
  useMutation,
  useQuery,
  gql,
} from "@apollo/client";
import { cardLink } from "../hoc/ApolloProvider";
import { Button } from '@mui/material';



export const createCardMGQL = gql`
mutation createCardM( $input:CardInput!){
  createCardM( input: $input) {
    success
    error
    id
    data
  }
}
`;

const CreateFlow = ( {onCardChange}) => {

  const [createCardM, { loading, error, data }] = useMutation(createCardMGQL, {
    //refetchQueries: [getFlowGQL, "getFlow"]
  });

  useEffect(()=>{
    if( data !== undefined ){
      console.log( 'createCard:'+data.createCardM.id)
      onCardChange( data.createCardM.id, data.createCardM.data);
      //onCreateCard(data.createCardM.id);
      /*
      let jdata = JSON.parse();
      editor.clear();
      editor.fromJSON(jdata);
      editor.trigger("process");    
      */
    }
  },[data]);

  if (loading) return <p>createCard ...</p>;
  if (error) return <p>Error!: {error.message}</p>;
  //console.log(data);

  function createFlow(){    
    createCardM({ variables: {   
      "input": {
              "title": "assess card",
              "category": "assess",
              //"linkto": cardLink().flowid,
              //"data": jsonString}
        }
    }});
    window.history.replaceState('data to be passed', 'floe editor', '/flow/');
    cardLink({ ...cardLink(), flowid:'', cardid:'', flowcardid:''});
  }

  return(
    <div style={{ zIndex: 200}}>
    <button onClick={() => createFlow()}>createFlow</button>
  </div>  );

} 

export const updateCardMGQL = gql`
mutation updateCardM( $id: ID! $input:CardUpdate!){
  updateCardM( id:$id, input: $input) {
    success
    error
    id
  }
}
`;

// http://localhost:3000/card/62beb9beb4a19a4c82d096ae
const getCardGQL = gql`
query getCard($id: ID!){
    getCard( id:$id){
    id
    card{
        id
        title
        category
        data
      }
    success
    error
    }
  
}
`;

function GetFlowByCardID( {onCardChange, cardid}){

  const { loading, error, data, networkStatus } = useQuery(
    getCardGQL,
    {
      variables: { "id": cardid},
      notifyOnNetworkStatusChange: true
      // pollInterval: 500
    }
  );
  let jsondata = "";  
  useEffect(()=>{
    if( jsondata !== "")
      reloadCard();
    // console.log('use effect flow editor');
  },[jsondata])

  if (networkStatus === 4) return; // <p>Refetching!</p>;
  if (loading) return; // <p>loading</p>;
  if (error) return; // <p>Error!: {error}</p>;
  
  if( data.getCard.error) return; // <p>Error!: {data.getCard.error}</p>
  jsondata = data.getCard.card.data;



  function reloadCard(){
    onCardChange( cardid, jsondata);
  }

  return(
    <div style={{ zIndex: 200}}>
      <button onClick={() => reloadCard()}>open</button>
    </div>  
  );

} 


// http://localhost:3000/card/62beb9beb4a19a4c82d096ae
const getFlowGQL = gql`
query getFlow($id: ID!){
    getFlow( id:$id){
    id
    flow{
        cards {
          id
          category
          data
        }
    }
    success
    error
    }
}
`;

function GetFlowByFlowID( {onCardChange, flowid}){

  const { loading, error, data, networkStatus } = useQuery(
    getFlowGQL,
    {
      variables: { "id": flowid},
      notifyOnNetworkStatusChange: true
      // pollInterval: 500
    }
  );
  let jsondata = "";  
  let cardid = "";

  useEffect(()=>{
    if( jsondata !== "")
      reloadCard();
    // console.log('use effect flow editor');
  },[jsondata])

  if (networkStatus === 4) return; // <p>Refetching!</p>;
  if (loading) return;// <p>loading</p>;
  if (error) return; // <p>Error!: {error}</p>;
  
  if( data.getFlow.error) return; // <p>Error!: {data.getFlow.error}</p>

  data.getFlow.flow.cards.map((v,i) =>{
    if( v.category === 'assess' ){
      jsondata = v.data;
      cardid = v.id;
      return;
    }
  })
  //jsondata = data.getFlow.flow.cards.data;



  function reloadCard(){
    onCardChange( cardid, jsondata);
  }

  return(
    <div style={{ zIndex: 200}}>
      <button onClick={() => reloadCard()}>open</button>
    </div> 
  );

} 


export const generateFlowGQL = gql`
mutation generateFlowM( $id: ID! $input:CardUpdate!){
  generateFlowM( id:$id, input: $input) {
    success
    error
    id
    data
  }
}
`;

function FlowGenerate( { cardid, editor}) {

  const [generateFlowM, { loading, error, data }] = useMutation(generateFlowGQL, {
    //refetchQueries: [getFlowGQL, "getFlow"]
  });

  useEffect(()=>{
    if( data !== undefined ){
      let jdata = JSON.parse(data.generateFlowM.data);
      editor.clear();
      editor.fromJSON(jdata);
      editor.trigger("process");    

      window.history.replaceState('data to be passed', 'floe editor', '/flow/'+data.generateFlowM.id);
      //cardLink({ ...cardLink(), flowid:jdata.nodes.info.data.body[0], cardid:jdata.nodes.info.data.body[1], flowcardid:jdata.nodes.info.data.body[2]});
      /*
      let host = 'https://fe.liflo.io';
      if( cardLink().dev ) host = 'http://localhost:3000';
      const url = host + '/flow/'+data.generateFlowM.id;
      document.location = url;
      */
    }
  },[data]);

  if (loading) return <p>generate Flow ...</p>;
  if (error) return <p>Error!: {error.message}</p>;
  //console.log(data);


  function uploadFlow(){
    
    const json = editor.toJSON();
    json.flowcardid = cardLink().flowcardid;
    const jsonString = JSON.stringify(json, null, "");
    generateFlowM({ variables: {   
      "id": cardid, 
      "input": {
              //"title": "",
              //"category": "assess",
              "linkto": cardLink().flowid,
              "data": jsonString}
        }
    });
  }

  function openFlow() {
    //const url = 'https://liflo.io/link/'+cardLink().flowid+'/'+cardLink().cardid;
    //const url = 'https://flow.liflo.io/flow/'+cardLink().flowid;
    //const url = 'http://192.168.0.65:3007/flow/'+cardLink().flowid;
    //debug 1 const url = 'http://localhost:3006/flow/'+cardLink().flowid;
    const url = ((window.location.host.indexOf('localhost') !== -1) ? "http://localhost:3006":"https://fg.liflo.io") + '/gen/'+cardLink().flowid;
    window.open(url, '_blank', "height=800,width=550");
  }

  function handleFileLoad(event) {
    const isconfirmed = window.confirm('This will overwrite all the current data, continue?');
    if (isconfirmed) {
        var file = event.target.files;
        console.log(file);
        const reader = new FileReader();
        reader.onload = (): void => {
          if (reader.result && typeof reader.result === "string") {
            var jdata = JSON.parse(reader.result);
            editor.clear();
            jdata.nodes['info'].data.body[0] = '';
            jdata.nodes['info'].data.body[1] = '';
            jdata.nodes['info'].data.body[2] = '';
            
            var ndata = {
              id: "liflo@0.1.0",
              nodes: {
                info: {
                  id: "info",
                  data: {
                    label: "info",
                    properties: [],
                    header: [],
                    status: "processing",
                    body: ["","",""]
                  },
                  inputs: {},
                  outputs: {},
                  position: [
                    -3222.3353377562357,
                    -1149.5548313503211
                  ],
                  name: "InfoCard"
                }
              }
            }

            Object.keys(jdata.nodes).forEach(function(key){
              console.log(jdata.nodes[key]);
              let nkey = key.substring(1);
              if( key !== 'info'){
                ndata.nodes[nkey] = jdata.nodes[key];
                ndata.nodes[nkey].id = nkey;
                if( ndata.nodes[nkey].inputs.act !== undefined )
                ndata.nodes[nkey].inputs.act.connections.map( (v:any,i:number)=>{
                  v.node = v.node.substring(1);
                })
                Object.keys(ndata.nodes[nkey].outputs).forEach(function(ikey){
                  if( ndata.nodes[nkey].outputs[ikey].connections[0] !== undefined ){
                    ndata.nodes[nkey].outputs[ikey].connections[0].node = ndata.nodes[nkey].outputs[ikey].connections[0].node.substring(1);
                  }
                })  
                console.log(ndata.nodes[nkey]);
              }
            })
            editor.fromJSON(ndata);
            
            console.log('fid:'+jdata.nodes['info'].data.body[0]);
            editor.trigger("process");  
            /*
            modify(this.mainEditor.editor.modules, json);
            var event = { target: { value: "Main View" } };
            this.mainEditor.editor.ModuleHandlerChangeEvent(event, false);
            */
          }
        };

        if (file.length > 0) {
            reader.readAsText(file[0]);
        }
    }
  }

  function handleMergeFileLoad(event) {
    /*
      this.mainEditor.editor.modules[this.mainEditor.editor.currentModule].data = this.mainEditor.editor.toJSON();
      var file = event.target.files;
      console.log(file);
      const reader = new FileReader();
      reader.onloadend = (e) => {
        
          var json = JSON.parse(e.target.result);
          mergeModify(this.mainEditor.editor.modules, json, this.mainEditor.editor, file[0].name.slice(0, -5));
          //this.mainEditor.editor.ModuleHandlerChangeEvent({ target: { value: "Main View" } }, false);
          
      }

      if (file.length > 0) {
          reader.readAsText(file[0]);
      }
      */
  }
/**
 function modify(obj, newObj) {

  Object.keys(obj).forEach(function (key) {
      delete obj[key];
  });

  Object.keys(newObj).forEach(function (key) {
      obj[key] = newObj[key];
  });

}

async function mergeModify(obj, newObj, editor, name) {
  var newkeys = [];
  for (const key of Object.keys(newObj)) {
      if (key in obj) {
          obj[name + '-' + key] = newObj[key];
          newkeys.push(name + '-' + key);
      } else {
          obj[key] = newObj[key];
          newkeys.push(key);
      }
  }
  alert('Please wait for all modules to load before continuing.')
  for (const key of newkeys) {
      await sleep(1000);
      editor.ModuleHandlerChangeEvent({ target: { value: key } }, false);
  }
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
<label htmlFor="merge-file-button">
<input type="file" hidden id="merge-file-button" onChange={handleMergeFileLoad} />
<Button variant="contained" component="span" color="primary" sx={{height:35, fontSize:12, textTransform: 'lowercase', lineHeight: '1'}}>load and merge cards</Button>
</label>

*/
  return(
      <div className="inline-flex">
        <button onClick={() => uploadFlow()}>card generate</button>
        <button onClick={() => openFlow()}>card execute</button>
        <label htmlFor="std-file-button">
            <input type="file" hidden id="std-file-button" onChange={handleFileLoad} />
            <Button variant="contained" component="span" color="primary" sx={{height:35, fontSize:12, textTransform: 'lowercase', lineHeight: '1'}}>load cards</Button>
        </label>
        <button >ver.{cardLink().version}</button>
      </div>
  );
}

// Little helpers ...
//  }https://awv3node-homepage.surge.sh/build/assets/${name}.svg${
  const url = (name:any, wrap = false) =>
  `${
    wrap ? 'url(' : ''
  }/${name}.svg${
    wrap ? ')' : ''
  }`

type SLProps = {
  // using `interface` is also ok
  type: string;
  id: string;
  index: string;
};


class SpringLayout extends Component<SLProps> {

    state = { pages: 3, mouseY: 0 , editor: false, flowid: "", cardid: ""}; //, mouseY: 0, topDeltaY: 0, isPressed: false, originalPosOfLastPressed: 0 }

    parallax = null;
    jsondata = "";
    flowid = "";
    cardid = "";

    editor = null;

  
    constructor(props: SLProps) {
      super(props);
      if( props.id !== undefined){
        //this.cardid = props.id;
        //this.detailView(true);
      }
    }

    componentDidMount() {
      if( this.props.id !== undefined){
        if(this.props.type === 'card'){
          this.flowid = this.props.id;
          this.cardid = this.props.index;
          this.setState({flowid: this.flowid, cardid: this.cardid});  
        }
        else if(this.props.type === 'flow'){
          this.flowid = this.props.id;
          this.cardid = this.props.index;
          this.setState({flowid: this.flowid, cardid: this.cardid});  
        }
      }
    }

    async detailView( detail:boolean){
        if( detail){
          //this.setState({ pages: 2 });
          //<div ref={el => init(el)} />
          
          //alert(this.jsondata);
          if( this.state.editor === false ){
            this.setState({ editor: true });

            const element = document.createElement("div");
            element.setAttribute("style", "width: '100vw', height: '100vh'");
            let dock = document.getElementById('dock');
            if( dock != null ) dock.appendChild(element);        
            let ret = await init(element, this.jsondata);
            this.editor = ret.editor;
          }
          else{
            let jdata = JSON.parse(this.jsondata);
            await this.editor.fromJSON(jdata); //data1);              
          }
          
          if( this.parallax != null ) this.parallax.scrollTo(1);  
          this.setState( { pages: 1 });
        }
        else{
            if( this.parallax != null ) this.parallax.scrollTo(0);  
        }
      }

    onCardChange(this, id, jsondata){
      //jsondata = target[target.selectedIndex].value; 
      this.jsondata = jsondata;
      this.detailView(true);
      this.cardid = id;
      this.setState({cardid: this.cardid}); 
      //alert(jsondata);
    }
        
    onFlowChange(this, id, jsondata){
      //jsondata = target[target.selectedIndex].value; 
      this.jsondata = jsondata;
      this.cardid = id;

      //alert(jsondata);
    }

    onFlowUpload(this){
      //jsondata = target[target.selectedIndex].value; 
      //this.editor;
      //const json = this.editor.toJSON();
    }

    createFlow(this, id){
      //jsondata = target[target.selectedIndex].value; 
      //this.jsondata = jsondata;
      this.cardid = id;

      //alert(jsondata);
    }

    render() {

        let cardid = this.state.cardid;
        let flowid = this.state.flowid;
        return (
          <div style={{ backgroundColor: '#253237' }}>
    
          <Parallax ref={ref => (this.parallax = ref)} pages={this.state.pages} style={{left: '0px',top: '0px'}}>
    
           
    
            <ParallaxLayer offset={0.9} speed={0.8} 
              factor={0.1}
              style={{ opacity: 0.1 }}>
              <img src={url('cloud')} onClick={() => this.detailView(true)}
                style={{ display: 'block', width: '10%', marginLeft: '20%' }}  alt=""/>
              { this.props.type === 'card'|| cardid !== '' && <GetFlowByCardID cardid={cardid} onCardChange={(id,data) => this.onCardChange(id, data)}/>}
              { this.props.type === 'flow' && <GetFlowByFlowID flowid={flowid} onCardChange={(id,data) => this.onCardChange(id, data)}/>}
              <br/>
              <CreateFlow onCardChange={(id,data) => this.onCardChange(id, data)} />
            </ParallaxLayer>
    
            <ParallaxLayer
                offset={0}
                speed={0}
                factor={0.08}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                  opacity: 1,
                }}>
                {
                  //<FlowGet onFlowChange={(id,data) => this.onFlowChange(id, data)}/>
                  //<AppBar userid={this.props.userid}>liflo.io</AppBar>
                }
            </ParallaxLayer>
    
     
    
    
            <ParallaxLayer offset={1} speed={0} style={{ backgroundColor: '#805E73' }} />
            <ParallaxLayer
              offset={1}
              speed={0}
              
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {
                // use this code : https://codesandbox.io/s/cold-tree-5g76p?file=/package.json
                // https://codesandbox.io/s/t899c?file=/src/rete.jsx
                //onClick={() => this.detailView(false)}
                //<div style={{ width: "100vw", height: "100vh" }}>
                //<Editor />
                <div className="dock" id="dock">
                </div>
              }
            </ParallaxLayer>


            <ParallaxLayer offset={1.0} speed={0} 
              factor={0.1}
              style={{ opacity: 0.5, width: "20%", left: "60%", height:10}}>
                { this.editor != null && 
                <div className="inline-flex">
                  <FlowGenerate cardid={cardid} editor={this.editor}/>
                </div>}
            </ParallaxLayer>

          </Parallax>
          </div>
        )
    }
}

export default SpringLayout;
