import Rete from "rete";
import { BodyControl } from "../Controls/BodyControl";
import { StatusControl } from "../Controls/StatusControl";
import { actionSocket } from "../Sockets/Sockets";

export class SwitchCardNode extends Rete.Component {
    static type = "SwitchCard";
    public task:any;

    constructor() {
        super(SwitchCardNode.type);
        this.task = {
            outputs: {}
        };
    }

    builder(node) {
        //console.log("Create Card", "SwitchCard", node.id, node.data);

        if(node.data.body === undefined && node.name === 'SwitchCard'){
            node.data = {
                label: "Select Card",
                properties: [],
                header: [],
                status: "processing",
                body: ["질문을 입력하세요.",{"cat":3,"type": 0, "action":["","","",""],"rules":[]},1,""],
                actions: [
                    {
                        id: "Connect_1",
                        type: "CONNECT_ACTION",
                        text: "다음",
                        img: "",
                        outputs:[],
                        pt:0.0,
                        wt:1.0
                    }
                ]
            };
            //node.addOutput( new Rete.Output( '101', "추가하세요. +", actionSocket, false));
        }

        node.data.body[1].cat = 3;
        const body = new BodyControl(
            this.editor,
            node.data.body,
            node.id + "_BODY",
            SwitchCardNode.type
        );

        const status = new StatusControl(
            this.editor,
            node.data.status,
            node.id + "_STATUS",
            SwitchCardNode.type
        );

        node.addControl(status).addControl(body);
        

        if ( node.data.properties && !node.data.properties.includes("ENTRY_POINT")) {
            node.addInput(new Rete.Input("act", "Link", actionSocket, true));
        }

        if (node.data.actions) {
            node.data.actions.forEach(action => {
                node.addOutput( new Rete.Output(action.id, action.text, actionSocket, false));
            });
        }
        
        return node;
    }

    worker(node, inputs, outputs) {
        //console.log("SwitchCard", "worker", node.id, node.data);
        if (!this.editor)
            return
        const nodeRef = this.editor.nodes.find(it => it.id === node.id)
        if (!nodeRef)
            return
            /*
        const reqNumExits = node.data.numExits ?? 0
        outputs['numExits'] = reqNumExits
        const exitsInput = <NumControl>nodeRef.controls.get('numExits')
        if (exitsInput)
            exitsInput.setValue(reqNumExits)
        const numExits = nodeRef?.outputs.size ?? 0 - 1
        if (numExits == reqNumExits)
            return
        if (numExits < reqNumExits) {
            for (let i = numExits; i < reqNumExits; i++)
                Sequence.addOutput(nodeRef, i)
            nodeRef.update()
        } else {
            for (let i = reqNumExits; i < numExits; i++) {
                const out = nodeRef.outputs.get(`out${i}`)
                if (out) {
                    for (const conn of [...out.connections])
                        this.editor.removeConnection(conn)
                    nodeRef.removeOutput(out)
                }
            }
            nodeRef.update()
        }      
        */  
    }
}
