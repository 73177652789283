import { height } from "@mui/system";
import * as Rete from "rete";
import { NodeEditor } from "rete";
import styled from "styled-components";

const FontSize = 24;
const MinHeight = FontSize + 18;
const StyledTextarea = styled.textarea`
background: transparent;
border-style: none;
box-shadow: none;
display: block;
font-family: "Roboto", sans-serif;
font-size: ${FontSize}px;
font-weight: lighter;
margin: 8px 0;
min-height: ${MinHeight}px;
outline: none;
overflow: hidden;
padding: 0;
text-align: left;
transition: height 0.2s ease;
width: 100%;
height: 100%;
`;


export class BodyControl extends Rete.Control {
  emitter: NodeEditor;
  props: {
    readonly: boolean;
    value: any;
    id: string,
    height: any;
    name: string;
    onChange: (value: any) => void;
  };
  update?: () => void;
  component: any;
  
  height = 5;
  comp = ({ value, height,readonly, onChange }) => (
    <StyledTextarea placeholder=" " 
        defaultValue={value[0]}
        id={'1'}
        readOnly={this.props.readonly}
        onChange={(e) => onChange(+e.target.value)}/>
  );

  constructor(
    emitter: Rete.NodeEditor,
    data: any,
    id: any, 
    elementType: string,
    readonly = true
  ) {
    super(id);
    this.emitter = emitter;
    this.key = id;
    this.component = this.comp;

    //const initial = data[id] || 0;

    //node.data[key] = initial;
    this.props = {
      readonly,
      id: id,
      value: data,
      height: height,
      name: elementType,      
      onChange: (v) => {
        this.setValue(v);
        //this.emitter.trigger("process");
      },
    };
  }

  setValue(val: any) {
    this.props.value[0] = val;
    this.putData(this.key, this.props.value);
    this.update && this.update();
  }
}