import { Component as ReteComponent, Output as ReteOutput} from "rete";
import { InfoControl } from "../Controls/InfoControl";
import { StatusControl } from "../Controls/StatusControl";
import { actionSocket } from "../Sockets/Sockets";
import { cardLink} from "../../../hoc/ApolloProvider";


export class InfoCardNode extends ReteComponent {
    static type = "InfoCard";
    public task:any;

    constructor() {
        super(InfoCardNode.type);
        this.task = {
            outputs: {}
        };
    }

    builder(node) {
        //console.log("Create Card", "InfoCard", node.id, node.data);

        if(node.data.body === undefined && node.name === 'InfoCard'){
            node.data = {
                label: "제목을 입력합니다.",
                properties: [],
                header: [],
                status: "processing",
                body: ["","",""],
            };
            //node.addOutput( new Rete.Output( '101', "추가하세요. +", actionSocket, false));
        }
        else{
            cardLink({ ...cardLink(), flowid:node.data.body[0], cardid:node.data.body[1], flowcardid:node.data.body[2]});
            //states.flowid = node.data.body[0];
            //states.startCardid = node.data.body[1];
        }

        const status = new StatusControl(
            this.editor,
            node.data.status,
            node.id + "_STATUS",
            InfoCardNode.type
        );

        const body = new InfoControl(
            this.editor,
            node.data.body,
            node.id + "_LINK",
            InfoCardNode.type
        );

        node.addControl(status).addControl(body);
        
        if (node.data.actions) {
            node.data.actions.forEach(action => {
                node.addOutput( new ReteOutput(action.id, action.text, actionSocket, false));
            });
        }
        
        return node;
    }

    worker(node, inputs, outputs) {
        //console.log("InfoCard", "worker", node.id, node.data);
        if (!this.editor)
            return
        const nodeRef = this.editor.nodes.find(it => it.id === node.id)
        if (!nodeRef)
            return
    }
}
