// deprecated
import React from "react";
import styled from "styled-components";
import { Control } from "rete";
import { ReactControl } from "./ReactControl";

export class StatusControl extends Control {
    public putData: any;
    public render:any;
    public component:any;
    public props: any;
	public update: any;   


    constructor(emitter, data, id, elementType, readonly = false) {
        super(id);
        const args = arguments;
        this.render = "react";
        this.component = ReactControl;
        this.props = {
            emitter,
            id,
            data,
            readonly,
            name: elementType,
            onChange: event => {
                this.putData(event.target.id, event.target.value);
                this.update();
            },
            putData: () => this.putData.apply(this, args as any),
            render: () => this.renderControl()
        };
    }

    renderControl() {
        return (<></>
        );
    }
}
/**
 * 
import { BotIndicator } from "../Views/BotIndicator";

const Styled = {
    StatusContainer: styled.div`
        height: 72px;
    `
};

            <Styled.StatusContainer>
                <BotIndicator awaitingInput={this.props.data === "true"} />
            </Styled.StatusContainer>
 */
