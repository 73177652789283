import React from "react";
import * as ReactDOM from 'react-dom';
import styled from "styled-components";
import { Icon } from "../Views/Icon";
//import NumPicker from "./NumPicker";
import OutputEditor from "./OutputEditor";
// MARK: Constants

const DefaultWidth = 65;

// MARK: Types



// MARK: Styles
//         color: rgba(0, 0, 0, 0.87);

const Styled = {
    Container: styled.div`
        position: relative;
        text-align: right;
        width: 100%;
        margin-bottom: 6px;
    `,
    EnumOption: styled.input`
        
        border-radius: 26px;
        border-width: 2px;
        border-style: solid;
        border-color: rgb(0, 177, 169);
        cursor: pointer;
        display: inline-block;
        font-size: 12px;
        font-weight: normal;
        font-family: "Roboto", sans-serif;
        flex-direction: row;
        letter-spacing: normal;
        line-height: 14px;
        margin: 0;
        padding: 0;
        text-align: center;
        text-indent: 0px;
        text-rendering: auto;
        text-shadow: none;
        text-transform: none;
        word-spacing: normal;
    `,
    EnumGhost: styled.div`
        opacity: 0.3;
        display: block;
        white-space: pre-wrap;
        word-wrap: break-word;
        visibility: hidden;
        position: absolute;
        top: 0;
        margin: 0;
        padding: 4px;
    `,
    PrimaryAction: styled.button`
    align-self: flex-end;
    background: linear-gradient(
        119.16deg,
        rgb(0, 118, 129) 0%,
        rgb(0, 132, 132) 100%
    );
    border: none;
    border-radius: 26px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 8px,
        rgba(0, 0, 0, 0.06) 0px 1px 4px;
    box-sizing: border-box;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    height: 46px;
    margin: 0;
    margin-top: 4px;
    margin-right: 0px;
    margin-bottom: 0;
    min-width: 100px;
    overflow: visible;
    padding: 9px 10px 12px 10px;
    -webkit-appearance: button;
    -webkit-box-pack: justify;
`/*
                <Styled.PrimaryAction>
                <Icon id={'1'} /><Icon id={'2'}/><Icon id={'3'}/><Icon id={'4'}/>
                </Styled.PrimaryAction>
*/
};

export const AnimateIn = ({ show, children }) => {

    return (<>
			{show && <div key="animate-in">{children}</div>}
            </>
    );
};

// MARK: Component
// eslint-disable-next-line @typescript-eslint/no-namespace
declare module EnumOption {
    export type Props = {
        value: string;
        outputs: [];
        pt: number;
        width: number;
        edit: boolean;
        key:string;
        vkey:string;
        actionType:string;
        onClick?: (key:any,action:any,data:any) => void;
    };

    export type State = {
        value?: string;
        width: number;
        showToolbar: boolean;
    };
}

export class EnumOptionControl extends React.Component<
    EnumOption.Props,
    EnumOption.State
> {
    mounted!: boolean;
    capsule: any;
    ghost: any;
    state = {
        width: DefaultWidth,
        value: null,
        showToolbar: false
    };

    constructor(props: EnumOption.Props) {
        super(props);

        this.state = {
            width: DefaultWidth,
            value: props.value,
            showToolbar: props.edit === true
        };

        this.setValue = this.setValue.bind(this);
        this.setWidth = this.setWidth.bind(this);
    }

    setWidth() {
        if (this.mounted) {
            //console.log("Alert", 'mounted');
            const element = this.ghost;
            let width = element.clientWidth * 1.2;
            this.setState({
                width: width
            });
        }
    }

    inFocus(ev: React.FocusEvent<HTMLInputElement>){
        if (ev.currentTarget.parentNode) {
            this.toggleToolbar();
            console.log("Alert", 'focused');
          /*
          ReactDOM.render(
          <Styled.PrimaryAction>
            <Icon id={'1'} /><Icon id={'2'}/><Icon id={'3'}/><Icon id={'4'}/>
            </Styled.PrimaryAction>,
            ev.currentTarget.parentNode);  
          //ev.currentTarget.parentNode.appendChild(p);
          */
        }
  
    }

    outFocus(ev: React.FocusEvent<HTMLInputElement>){
        if (ev.currentTarget.parentNode) {
            this.toggleToolbar();
            console.log("Alert", 'focus out');
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.setWidth();
        window.setTimeout(() => this.setWidth(), 1);
        this.setState({
            showToolbar: this.props.edit
        });        
    }

    setValue(event: { target: { value: any } }) {
        const { value } = event.target;
        this.setState({ value });
        this.props.onClick(this.props.vkey,5,value);
    }

    onClickAdd = () => {
        this.props.onClick(this.props.vkey,1,"");
    };
    onClickUp = () => {
        this.props.onClick(this.props.vkey,2,"");
    };
    onClickDown = () => {
        this.props.onClick(this.props.vkey,3,"");
    };
    onClickRemove = () => {
        this.props.onClick(this.props.vkey,4,"");
    };

    onChangePt = (pt) => {
        this.props.onClick(this.props.vkey,6,pt);
    };

    onChangeOutputs = (outputs) => {
        this.props.onClick(this.props.vkey,6,outputs);
    };

    inputField() {
        const { width, value } = this.state;
        //console.log("Alert", value);

        const toolbar = (
            <AnimateIn show={this.props.edit}>
                <Styled.PrimaryAction>
                    <Icon onClick={this.onClickAdd} id={'1'}/>
                    <Icon onClick={this.onClickRemove} id={'4'}/>
                    <OutputEditor onChange={(outputs)=>this.onChangeOutputs(outputs)} outputs={this.props.outputs} wt={1}/>
                </Styled.PrimaryAction>
            </AnimateIn>
        );
        //<NumPicker onChange={(outputs)=>this.onChangePt(outputs)} pt={this.props.outputs} wt={1}/>
        let background = this.props.actionType === 'CONNECT_ACTION' ? '#ffffff' : '#008888';
        let color = this.props.actionType === 'LINK_ACTION' ? '#ffffff' : '#000000';
        return (
            <div className="inline-flex"              
            //onMouseOut={this.toggleToolbar}
            //onMouseOver={this.toggleToolbar}
            >
                {toolbar}                
                <Styled.EnumOption
                    style={{
                        width,
                        background,
                        color
                    }}
                    value={value}
                    onChange={this.setValue}
                    onKeyUp={this.setWidth}
                    readOnly={this.props.edit?false:true} 
                />
            </div>
        );
    }

    ghostField() {
        return (
            <Styled.EnumGhost
                className="enum-ghost"
                ref={c => (this.ghost = c)}
                aria-hidden="true">
                {this.state.value}
            </Styled.EnumGhost>
        );
    }

    toggleToolbar = () => {

        this.setState((prevs) => ({
			showToolbar: !prevs.showToolbar
		}));
        //this.setState({ width: this.state.showToolbar === true ? this.state.width + 100:  this.state.width});
	};

    render() {


        return (
            <Styled.Container>
                {this.inputField()}
                {this.ghostField()}
            </Styled.Container>
        );
    }
}
/**
 *                     <Icon onClick={this.onClickUp} id={'2'}/>
                    <Icon onClick={this.onClickDown} id={'3'}/>

 */