import React, {useEffect} from "react";
import {SpringLayout, ImageCard, BgCard, FileDropzone}   from '../../layout'
import {useParams} from "react-router-dom";
  
export const App = (props) => {
    //let type = 'flow';
    let userid=123;
    let flowid='1234';
    let nIndex = 0;

    const handleCloseUploadPicture = () => {
      //setOpenUploadPicture(false);
    };

    useEffect(() => {
      document.body.style.backgroundColor = "#253237";
      document.body.style.margin = "10px";
  
      return () => {
        document.body.style.backgroundColor = "white";
      };
    }, []);

    // http://localhost:3000/flow/62beb9beb4a19a4c82d096ae
    let { type,id,index } = useParams();
    //console.log( "connect:"+type+"/"+id+"/"+index)
    return (
        <div className="App">
              { (type === 'flow' || type === 'card') && (
                <SpringLayout type={type} id={id} index={index}/>
              )}
              { type === 'image' && (
                <ImageCard  cardid={id} title={index}/>
              )}
              { type === 'bg' && (
                <BgCard  cardid={id} title={index}/>
              )}
              { type === 'file' && (
                <FileDropzone />
              )}
              { type === undefined && (
                <SpringLayout type={undefined} id={undefined} index={undefined}/>
                /**


<div style={{ width: "100vw", height: "100vh" }}>Hello</div>
                 <div style={{ width: "100vw", height: "100vh" }}>
                <div ref={el => init(el)} />
                </div>
                 * 
                 */
                //<FlowO id={this.props.match.params.id} index={this.props.match.params.index} />
                )}
        </div>
    );
};

export default App;